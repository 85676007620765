import React, { useEffect, useState, useMemo } from 'react';
import { InventoryDetailInputBlock, RunInventoryDetailInputRecorded } from 'shared/lib/types/views/procedures';
import PartLabel from './PartLabel';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import useParts from '../hooks/useParts';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ItemDetail } from '../types';
import ItemSelect from './ItemSelect';
import SubstepNumber from '../../components/SubstepNumber';
import RunControlledInput from '../../elements/RunControlledInput';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

interface InventoryDetailInputProps {
  content: InventoryDetailInputBlock;
  recorded?: RunInventoryDetailInputRecorded;
  onRecordValuesChanged: (contentId: string, recorded: RunInventoryDetailInputRecorded) => void;
  isEnabled?: boolean;
  blockLabel?: string;
}

const InventoryDetailInput = React.memo(
  ({ content, recorded, onRecordValuesChanged, isEnabled, blockLabel }: InventoryDetailInputProps) => {
    const { services, currentTeamId } = useDatabaseServices();
    const { getPartByRevisionId, getPart } = useParts();
    const [part, setPart] = useState<Part | null>(null);
    const [itemDetails, setItemDetails] = useState<Array<ItemDetail>>([]);
    const restrictedPart = isPartRestricted(getPart(content.part_id || ''));

    useEffect(() => {
      services.manufacturing
        .listItemDetails()
        .then(setItemDetails)
        .catch(() => {
          /* no-op */
        });
    }, [services.manufacturing]);

    // Part load
    useEffect(() => {
      if (content.part_revision_id) {
        // Note: getPartByRevisionId actually returns the latest rev, regardless of the part_revision_id
        const latestRev = getPartByRevisionId(content.part_revision_id);
        if (latestRev) {
          services.manufacturing.getPart(latestRev.id, { revisionId: content.part_revision_id }).then(setPart);
        }
      }
    }, [services.manufacturing, getPartByRevisionId, content.part_revision_id]);

    const updateDetailValue = (value: string) => {
      onRecordValuesChanged(content.id, {
        item_id: '',
        ...recorded,
        value,
      });
    };

    const handleChangeItem = (props) => {
      // TODO: Update ItemSelect to avoid calling onChangeItem on load
      if (props === null) {
        return;
      }
      onRecordValuesChanged(content.id, {
        value: '',
        ...recorded,
        item_id: props?.id,
      });
    };

    const detailName = useMemo(() => {
      const detail = itemDetails.find((detail) => detail.id === content.detail_id);
      return detail?.name ?? '';
    }, [itemDetails, content.detail_id]);

    /**
     * unset value must be blank string as 'null' is not allowed by prop type
     * and 'undefined' leads to subtle bugs.
     */
    const detailValue: string = recorded?.value ?? '';

    const isLoading = useMemo(() => !detailName || !part, [detailName, part]);

    if (restrictedPart) {
      return (
        <div className="ml-5 my-4">
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </div>
      );
    }

    return isLoading ? null : (
      <div className="flex flex-row items-center ml-4 my-4">
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        <div className="flex flex-row space-x-6 items-center">
          {part && <PartLabel teamId={currentTeamId} part={part} />}
          <div className="flex flex-col w-56">
            <div className="text-xs font-medium text-gray-500">Serial / Lot #</div>
            <ItemSelect
              itemId={recorded?.item_id}
              partId={part?.id}
              partRevision={part?.rev}
              onChangeItem={handleChangeItem}
              isDisabled={!isEnabled}
            />
          </div>
          <div className="flex flex-col">
            <div className="text-xs font-medium text-gray-500">{detailName}</div>
            <RunControlledInput
              recorded={detailValue}
              aria-label="Enter Value"
              className="text-sm px-2 h-[38px] py-1.5 w-36 border border-gray-400 rounded disabled:bg-gray-100"
              onRecordValue={updateDetailValue}
              disabled={!isEnabled}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default InventoryDetailInput;
