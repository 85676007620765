import React, { useCallback, useMemo } from 'react';
import { getIn, setIn } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';

import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '../../contexts/SettingsContext';
import { isEmptyValue } from 'shared/lib/text';
import RunFieldSetTableCell from './RunFieldSetTableCell';
import { SIGNOFF_COLUMN_TYPE } from './TableInputConstants';
import RunSignoffCell from './RunSignoffCell';
import UnitDisplay from '../Settings/Units/UnitDisplay';
import tableUtil, { STATIC_COLUMN_TYPES } from 'shared/lib/tableUtil';
import signoffUtil from 'shared/lib/signoffUtil';
import { useRunContext } from '../../contexts/RunContext';
import procedureUtil from '../../lib/procedureUtil';
import tableInputUtil from '../../lib/tableInputUtil';
import CommentButton from '../CommentButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commentToActivity } from 'shared/lib/comment';
import TestPointCell from './TestPointCell';

/*
 * Renders a Table with Column Headers that have a three dot menu context and/or cells that
 * are enabled and disabled according to props passed in. The props represent the following:
 *
 *  path: initialPath to the table_input object in a procedure
 *  columns: An array of columnHeader objects that include input_type, units and name
 *  cells: The values for all rows.
 *  areRowsDisabled: Boolean that sets the cells to a disabled field or enabled field
 *  values: the recorded formik values that will be passed on to recordValuesChanged and to non formik cells when rows are disabled
 *  errors: Object with error values for the cells
 *  recordValuesChanged: function that will be called onBlur of field inputs
 *  setFieldValue: formik function to set value in form
 */

const RunTable = React.memo(
  ({
    path,
    columns,
    cells,
    areRowsDisabled,
    values,
    errors,
    recordValuesChanged,
    setFieldValue,
    saveTextComment,
    editTextComment,
  }) => {
    const { auth } = useAuth();
    const { isPreviewMode, previewUserRoles, isRun } = useRunContext();
    const { getListValues } = useSettings();

    /**
     * If name(path) and value are passed, this function will set it in
     * a cloned values object and call recordValues with updated values.
     * Note: This is a workaround to get safari working with checkboxes
     * and not-controlled formik fields.
     */
    const onRecordValueChanged = useCallback(
      (name, value, signoffId) => {
        const oldValue = getIn(values, name);
        if (value === oldValue) {
          return;
        }

        recordValuesChanged(name, value, signoffId);
      },
      [recordValuesChanged, values]
    );

    const signoffColumnIndices = useMemo(() => tableUtil.getAllSignoffColumnIndices(columns), [columns]);

    const displayRows = useMemo(() => {
      const { values: innerValues } = values;
      let evaluatedValuesCache = cloneDeep(values);

      return innerValues.map((row, rowIndex) => {
        const isRowSignedOff = tableUtil.isAnyRequiredRowSignoffComplete({
          columns,
          row,
        });
        const isRowComplete = tableUtil.areAllRequiredRowSignoffsComplete({
          columns,
          row,
        });

        // If there are signoff columns, check user auth for those rows.
        const hasUpdateRowPermission =
          signoffColumnIndices.length === 0 || // Has permission if no signoffs
          signoffColumnIndices.some((signoffColumnIndex) => {
            const signoffs = innerValues[rowIndex][signoffColumnIndex];

            if (signoffs && signoffs.length && tableUtil.isSignoffCell(signoffs)) {
              return isPreviewMode
                ? signoffUtil.isGenericSignoffRequired(signoffs) ||
                    isEmpty(previewUserRoles) ||
                    signoffs.some((signoff) =>
                      signoff.operators.some((operator) => previewUserRoles.includes(operator))
                    )
                : signoffUtil.isGenericSignoffRequired(signoffs) ||
                    signoffs.some((signoff) => signoff.operators.some((operator) => auth.hasOperatorRole(operator)));
            }

            return true;
          });

        return columns.map((column, columnIndex) => {
          // For backwards compatibility when we did not have column_type.
          const columnType = column.column_type || 'input';

          const isCellDisabled = areRowsDisabled || STATIC_COLUMN_TYPES.includes(columnType) || !hasUpdateRowPermission;
          const disabledDueToRowErrors =
            columnType === SIGNOFF_COLUMN_TYPE &&
            row.some((_cell, _columnIndex) => getIn(errors, `${path}[${rowIndex}][${_columnIndex}]`));

          const cellPath = `${path}[${rowIndex}][${columnIndex}]`;
          const inputType = columnType === 'text' ? 'text' : column.input_type; // Regardless of input_type, if column_type is text use text input.
          const originalValue = STATIC_COLUMN_TYPES.includes(columnType)
            ? cells[rowIndex][columnIndex]
            : getIn(values, cellPath);
          // If column type is text and it begins with an "=", try to evaluate the expression.
          const isExpression = columnType === 'text' && originalValue[0] === '=';
          let evaluatedExpression;

          if (isExpression) {
            evaluatedExpression = tableInputUtil.evaluateExpression({
              expression: originalValue,
              values: evaluatedValuesCache.values,
            });

            // Update valueCache evaluated values to allow expression chaining.
            evaluatedValuesCache = setIn(evaluatedValuesCache, cellPath, evaluatedExpression);
          }

          return {
            name: cellPath,
            columnType,
            inputType: isExpression ? 'expression' : inputType,
            label: column.units ? <UnitDisplay unit={column.units} /> : column.units,
            list: column.list,
            isDisabled: (() => {
              if (columnType === SIGNOFF_COLUMN_TYPE) {
                return isCellDisabled || disabledDueToRowErrors;
              }
              if (columnType === 'comment') {
                return !isRun || isPreviewMode;
              }
              return isCellDisabled || isRowSignedOff;
            })(),
            isComplete: isRowComplete,
            value: isEmptyValue(originalValue) ? '' : originalValue,
            evaluatedExpression: isEmptyValue(evaluatedExpression) ? '' : evaluatedExpression,
            error: getIn(errors, cellPath),
            ...(columnType === 'comment' && {
              comments: (cells[rowIndex][columnIndex] ?? []).map(commentToActivity),
            }),
          };
        });
      });
    }, [
      values,
      columns,
      signoffColumnIndices,
      isPreviewMode,
      previewUserRoles,
      auth,
      areRowsDisabled,
      path,
      errors,
      cells,
      isRun,
    ]);

    const getCellColor = useCallback((cell) => {
      if (cell.isComplete) {
        return `bg-emerald-100`;
      }
      return `${cell.isDisabled ? 'bg-gray-100' : 'bg-white'}`;
    }, []);

    return (
      <table className="w-full h-full table-fixed">
        <thead>
          <tr>
            {columns.map((column, index) => {
              const columnPath = `${path}.columns[${index}]`;
              return (
                <th
                  key={columnPath}
                  align="left"
                  className="h-fit border bg-gray-200 border-gray-400 bg-opacity-75"
                  style={{
                    width: `${column.width}%`,
                    maxWidth: `${column.width}%`,
                    minWidth: `${column.width}%`,
                  }}
                >
                  <div className="flex flex-col items-start h-full w-full">
                    <div className="bg-gray-200 text-gray-500 text-center text-xs w-full">
                      {procedureUtil.displaySectionKey(index, 'letters')}
                    </div>
                    <div className="border-t border-gray-400 bg-gray-300 bg-opacity-75 h-full w-full">
                      {column.column_type === 'comment' && (
                        <div
                          className="flex items-center justify-center h-full w-full"
                          aria-label="comment column header"
                        >
                          <FontAwesomeIcon icon="comment" size="lg" className="p-2 text-gray-500" />
                        </div>
                      )}
                      {column.column_type !== 'comment' && (
                        <RunFieldSetTableCell type="text" value={column.name} isDisabled={true} />
                      )}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {displayRows.map((row, rowIndex) => {
            const rowPath = `rows[${rowIndex}]`;

            return (
              <tr key={rowPath} className="h-full" aria-label="Field Input Table Row">
                {row.map((cell, cellIndex) => (
                  <td
                    key={`${rowPath}[${cellIndex}]`}
                    /*
                     * Require checkbox cells to be at least 44 x 44px to allow for easy clickability
                     * bg-clip-padding ensures table borders show up in Firefox
                     */
                    className={`relative bg-clip-padding h-full border border-gray-400 ${getCellColor(cell)}`}
                    style={{ minWidth: '3.5rem' }}
                  >
                    {cellIndex === 0 && (
                      <div className="flex absolute -translate-x-full -left-px top-0 bottom-0 w-6 text-gray-500 items-center justify-center bg-gray-200 text-xs border-y border-l border-gray-400 my-0.5">
                        {rowIndex + 1}
                      </div>
                    )}
                    {cell.columnType === SIGNOFF_COLUMN_TYPE && (
                      <div className="py-1 min-w-fit">
                        <RunSignoffCell
                          signoffs={cell.value}
                          isDisabled={cell.isDisabled}
                          onSignoffsChanged={(updatedSignoffs, signoffId) => {
                            onRecordValueChanged(cell.name, updatedSignoffs, signoffId);
                          }}
                        />
                      </div>
                    )}
                    {cell.columnType === 'comment' && (
                      <div className="flex items-center justify-center w-full h-full">
                        <CommentButton
                          comments={cell.comments}
                          showNewCommentIcon={true}
                          saveTextComment={
                            saveTextComment &&
                            ((newComment, mentions) => saveTextComment(rowIndex, cellIndex, newComment, mentions))
                          }
                          saveEditComment={
                            editTextComment &&
                            ((comment, commentId) => editTextComment(rowIndex, cellIndex, comment, commentId))
                          }
                          size="full"
                          isDisabled={cell.isDisabled}
                          contextTitle={`Cell ${procedureUtil.displaySectionKey(cellIndex, 'letters')}${rowIndex + 1}`}
                        />
                      </div>
                    )}
                    {cell.columnType === 'test_point' && (
                      <TestPointCell testPoint={cell.value} backgroundColor={getCellColor(cell)} />
                    )}
                    {![SIGNOFF_COLUMN_TYPE, 'comment', 'test_point'].includes(cell.columnType) && (
                      <RunFieldSetTableCell
                        name={cell.name}
                        type={cell.inputType}
                        label={cell.label}
                        options={cell.list ? getListValues(cell.list) : []}
                        value={tableUtil.isSignoffCell(cell.value) ? '' : cell.value}
                        evaluatedExpression={cell.evaluatedExpression}
                        isDisabled={cell.isDisabled}
                        error={cell.error}
                        setFieldValue={setFieldValue}
                        onBlur={onRecordValueChanged}
                      />
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);

export default RunTable;
