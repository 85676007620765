import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BASIC_REFRESH_TRY_AGAIN_MESSAGE } from '../../lib/messages';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import SimpleDisplayTable from './SimpleDisplayTable';
import Label from '../../components/Label';
import useProjectFilterHelper from '../hooks/useProjectFilterHelper';
import usePopup from '../../hooks/usePopup';
import { Case, CaseRecord, TestCaseRun } from 'shared/lib/types/testing';
import AssessmentIndicator from './AssessmentIndicator';
import {
  procedureViewPath,
  runViewStepPath,
  testingTestCasesPath,
  testingTestCasesVersionPath,
  testingTestCasesViewPath,
} from '../../lib/pathUtil';
import renderDateTime from '../../components/Home/Renderers/DateTime';
import renderParticipants from '../../components/Home/Renderers/Participants';
import testCaseRunsUtil from '../testCaseRunsUtil';
import DataGrid, { Column } from 'react-data-grid';
import MissingValueLabel from '../../manufacturing/components/MissingValueLabel';
import StickyHeader from './StickyHeader';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { VersionSelectOption } from './Versions/VersionDisplay';
import VersionHistory from './Versions/VersionHistory';
import HazardPopup from './HazardPopup';

const TEST_CASE_RUN_ROW_HEIGHT = 45;
const HEADER_ROW_HEIGHT = 36;
const NO_SCROLL_PAD = 2; // Adding this to grid height removes the inner vertical scrollbar

interface TestCaseViewProps {
  testCase?: Case | null;
  isPopup?: boolean;
  versionId?: string;
  versions?: Array<CaseRecord>;
}

const TestCaseView = ({ testCase, versionId, versions, isPopup = false }: TestCaseViewProps) => {
  const { currentTeamId, services: databaseServices } = useDatabaseServices();
  const [globalErrors, setGlobalErrors] = useState<Error>();
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();
  const history = useHistory();
  const { getProjectName } = useProjectFilterHelper();

  const onDeleteCase = useCallback(() => {
    if (!testCase) {
      setGlobalErrors(new Error(BASIC_REFRESH_TRY_AGAIN_MESSAGE));
      return;
    }
    const prompt = 'This test point will be permanently removed. Are you sure you want to proceed?';
    if (!window.confirm(prompt)) {
      return;
    }
    databaseServices.testing
      .deleteCase(testCase.id)
      .then(() => history.push(testingTestCasesPath(currentTeamId)))
      .catch(() => {
        setGlobalErrors(new Error(`Unable to delete test point. ${BASIC_REFRESH_TRY_AGAIN_MESSAGE}`));
      });
  }, [testCase, databaseServices.testing, history, currentTeamId]);

  const versionSelectOptions: Array<VersionSelectOption> =
    versions && testCase
      ? versions.map((version, index) => {
          return {
            id: version.id.toString(),
            version: version.record?.version,
            timestamp: version.timestamp,
            path:
              index === 0
                ? testingTestCasesViewPath(currentTeamId, testCase.id)
                : testingTestCasesVersionPath(currentTeamId, testCase.id, version.id),
          };
        })
      : [];

  let selectedVersion: VersionSelectOption | undefined = undefined;
  if (versionId) {
    selectedVersion = versionSelectOptions?.find((option) => option.id === versionId.toString());
  }
  if (!versionId && versionSelectOptions?.length > 0) {
    selectedVersion = versionSelectOptions[0];
  }
  const isLatestVersion = versionSelectOptions?.length > 0 && selectedVersion?.id === versionSelectOptions[0].id;

  const getTableCellsByRowForConditions = () =>
    (testCase?.test_case_conditions ?? []).map((testCaseCondition) => [
      <label>{testCaseCondition.name}</label>,
      <label className="flex flex-row gap-x-[2px]">
        {`${testCaseCondition.value}`} <UnitDisplay unit={testCaseCondition.units} />
      </label>,
    ]);

  const getTableCellsByRowForHazards = () =>
    testCase?.hazards?.map((hazard) => [
      <>
        <label onClick={(e) => handlePopupOpen(e, hazard.id)} className="text-blue-600 hover:underline truncate">
          {hazard.name}
        </label>
        {hazard && (
          <HazardPopup isPopUpShown={activeChildId === hazard.id} onPopupClose={handlePopupClose} hazard={hazard} />
        )}
      </>,
      <label>{hazard.category}</label>,
      <AssessmentIndicator hazard={hazard} />,
    ]);

  const testCaseRunsColumns = useMemo<Array<Column<TestCaseRun>>>(
    () => [
      {
        key: 'plan',
        name: 'Test Plan',
        width: '30%',
        cellClass: 'cursor-pointer',
        renderCell({ row }: { row: TestCaseRun }) {
          return (
            <div className="flex flex-col">
              <Link
                to={procedureViewPath(currentTeamId, row.procedure_id)}
                className="text-xs text-blue-600 hover:underline"
              >
                {row.procedure_code}
              </Link>
              <div className="text-xs mt-0 text-gray-400 truncate">{row.procedure_name}</div>
            </div>
          );
        },
      },
      {
        key: 'step',
        name: 'Step',
        width: '15%',
        cellClass: 'cursor-pointer',
        renderCell({ row }: { row: TestCaseRun }) {
          return (
            <div className="flex flex-col">
              <Link
                to={runViewStepPath(currentTeamId, row.run_id, row.step_id)}
                className="text-xs text-blue-600 hover:underline"
              >
                {row.step_name}
              </Link>
            </div>
          );
        },
      },
      {
        key: 'status',
        name: 'Status',
        width: '10%',
        renderCell({ row }: { row: TestCaseRun }) {
          return (
            <Label
              text={testCaseRunsUtil.getTestCaseStatusForRun(row)}
              color={testCaseRunsUtil.getStatusBackgroundColorForRun(row)}
            />
          );
        },
      },
      {
        key: 'started_at',
        name: 'Run Started At',
        width: '15%',
        renderCell({ row }: { row: TestCaseRun }) {
          return renderDateTime(row.run_start_time);
        },
      },
      {
        key: 'step_signoffs',
        name: 'Signoff',
        width: '10%',
        renderCell({ row }: { row: TestCaseRun }) {
          return row.step_signoffs ? renderParticipants(row.step_signoffs) : null;
        },
      },
      {
        key: 'completed_at',
        name: 'Completed At',
        width: '19.9%',
        renderCell({ row }: { row: TestCaseRun }) {
          return renderDateTime(row.completed_time);
        },
      },
    ],
    [currentTeamId]
  );

  return (
    <>
      {!isPopup && (
        <>
          <StickyHeader
            code={testCase?.version || ''}
            name={testCase?.name ?? ''}
            source={{ title: 'Test Points', onClick: () => history.push(testingTestCasesPath(currentTeamId)) }}
            showEditDeleteButtons={Boolean(testCase) && !versionId}
            onDelete={onDeleteCase}
            resourcePath={testingTestCasesViewPath(currentTeamId, testCase?.id ?? '')}
            projectId={testCase?.project_id}
          />
          <div className="flex flex-row items-center mt-8 mb-2">
            <div className="text-lg">{testCase?.name}</div>
            {versions && versions.length > 0 && (
              <VersionHistory
                version={selectedVersion || versionSelectOptions[0]}
                isLatestVersion={isLatestVersion}
                versionOptions={versionSelectOptions}
              />
            )}
          </div>
        </>
      )}
      {globalErrors && <p className="self-center mt-2 text-red-700">Error: {globalErrors.message}</p>}
      {testCase && (
        <>
          {/* Since project is the only field present in this card, only show it if project is present */}
          {testCase.project_id && (
            <div className="flex flex-row mt-2 gap-x-2">
              <label className="text-sm text-gray-500">Project</label>
              {testCase.project_id && (
                <div className="flex flex-grow">
                  <Label text={getProjectName(testCase.project_id) || ''} color="bg-gray-200" />
                </div>
              )}
            </div>
          )}
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row gap-x-4 mt-4">
              <div className={isPopup && (!testCase.hazards || testCase.hazards.length === 0) ? 'w-full' : 'w-1/2'}>
                <div className="text-sm text-slate-500">Conditions</div>
                <div className="flex flex-row gap-x-4">
                  {(!testCase.test_case_conditions || testCase.test_case_conditions.length === 0) && (
                    <div className="text-sm text-black p-2">No associated conditions for this test point.</div>
                  )}
                  {testCase.test_case_conditions && testCase.test_case_conditions.length > 0 && (
                    <SimpleDisplayTable
                      tableName="conditions"
                      columnNames={['Name', 'Value and Units']}
                      tableCellsByRow={getTableCellsByRowForConditions()}
                      tableStyle="borders"
                    />
                  )}
                </div>
              </div>

              {(isPopup && (!testCase.hazards || testCase.hazards.length === 0)) || versionId ? null : (
                <div className={!isPopup ? 'w-1/2' : ''}>
                  <div className="text-sm text-slate-500">Hazards</div>
                  {(!testCase.hazards || testCase.hazards.length === 0) && (
                    <div className="text-sm text-black p-2">No associated hazards for this test point.</div>
                  )}
                  {testCase.hazards && testCase.hazards.length > 0 && (
                    <SimpleDisplayTable
                      tableName="hazards"
                      columnNames={['Name', 'Category', 'Analysis']}
                      tableCellsByRow={getTableCellsByRowForHazards()}
                      tableStyle="borders"
                    />
                  )}
                </div>
              )}
            </div>
            {!isPopup && !versionId && (
              <div>
                <div className="text-sm text-slate-500">Run History</div>
                <div className="h-60">
                  {testCase.runs && testCase.runs.length > 0 ? (
                    <DataGrid
                      className="rdg-light"
                      style={{
                        width: '100%',
                        minWidth: '600px',
                        height:
                          HEADER_ROW_HEIGHT +
                          NO_SCROLL_PAD +
                          TEST_CASE_RUN_ROW_HEIGHT * Math.min(4, testCase.runs.length),
                      }}
                      columns={testCaseRunsColumns}
                      rows={testCase.runs}
                      headerRowHeight={HEADER_ROW_HEIGHT}
                      rowHeight={TEST_CASE_RUN_ROW_HEIGHT}
                    />
                  ) : (
                    <div className="w-full pl-4 pr-4 bg-white rounded shadow">
                      <MissingValueLabel text="No run occurrences found" size="sm" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TestCaseView;
