import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { StepSnippet } from '../../lib/views/settings';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import FieldSetProcedureStep from '../FieldSetProcedureStep';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import validateUtil from '../../lib/validateUtil';
import ModalSaveSnippet from './ModalSaveSnippet';
import { BlockTypes } from '../Blocks/BlockTypes';
import attachmentUtil from '../../lib/attachmentUtil';
import FieldSetSnippetHeader from './FieldSetSnippetHeader';
import PromptBeforeUnload from '../Prompt/PromptBeforeUnload';
import AddContentMenu from '../ContentMenu/AddContentMenu';
import getStepAddContentItems from '../ContentMenu/stepAddContentItems';
import { Actions } from '../ContentMenu/addContentTypes';
import procedureUtil from '../../lib/procedureUtil';
import { SelectionContextProvider } from '../../contexts/Selection';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import FlashMessage from '../FlashMessage';
import Selectable, { Boundary } from '../Selection/Selectable';
import clipboardUtil from '../../lib/clipboardUtil';
import snippetUtil from '../../lib/snippetUtil';
import {
  DatabaseServices,
  copyItemToClipboard,
  selectClipboardItem,
  selectProceduresNoDraftsForReleased,
} from '../../contexts/proceduresSlice';
import { useMixpanel } from '../../contexts/MixpanelContext';

interface FieldSetStepSnippetProps {
  snippet: StepSnippet;
  onClose;
  onRemove?: (id: string) => Promise<void>;
  onSaveSuccess?: () => void;
  isDuplicate?: boolean;
  testingModule?: boolean;
}

const FieldSetStepSnippet = ({
  snippet,
  onClose,
  onRemove,
  onSaveSuccess,
  isDuplicate,
  testingModule,
}: FieldSetStepSnippetProps) => {
  const store = useStore();
  const [present, setPresent] = useState(snippet);
  const [showsSaveStepSnippet, setShowsSaveStepSnippet] = useState(false);
  const [stepErrors, setStepErrors] = useState({});
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const { setIsCollapsed, isCollapsedMap } = useExpandCollapse();
  const [showsDuplicateModal, setShowsDuplicateModal] = useState(isDuplicate);

  const dispatch = useDispatch();
  const { mixpanel } = useMixpanel();
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));

  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    if (!clipboardBlock || snippetUtil.validateBlockForSnippet(clipboardBlock) !== undefined) {
      return undefined;
    }
    return clipboardBlock;
  }, [clipboardItem]);

  const mixpanelTrack = useCallback(
    (trackingKey) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const onCopyStep = useCallback(() => {
    mixpanelTrack('Copy Step');
    const copiedStep = procedureUtil.copyStep(present.step);
    const clipboardItemStep = clipboardUtil.createClipboardItemStep(copiedStep);
    dispatch(copyItemToClipboard(currentTeamId, clipboardItemStep));
    setFlashMessage('Step copied');
  }, [currentTeamId, dispatch, mixpanelTrack, present]);

  const onPasteBlock = useCallback(() => {
    if (!clipboardBlock) {
      return;
    }
    mixpanelTrack('Paste Block');
    const updatedSnippet = cloneDeep(present);
    const blockCopy = procedureUtil.copyBlock(clipboardBlock);
    updatedSnippet.step.content.push(blockCopy);
    setPresent(updatedSnippet);
  }, [clipboardBlock, mixpanelTrack, present]);

  // Used as a filler prop for ProcedureContextProvide
  const procedureShell = useMemo(() => {
    const shell = {
      _id: '',
      code: '',
      name: '',
      description: '',
      sections: [{ steps: [present] }],
    };
    return shell;
  }, [present]);

  // Validates this step for saving as a step snippet.
  const onValidateStepSnippet = useCallback(async () => {
    const step = present.step;

    // Validate step.
    const procedures = selectProceduresNoDraftsForReleased(store.getState(), currentTeamId); // get state on demand to prevent re-rendering of component every time procedures change
    const { errors: validationErrors } = await validateUtil.validateStep({
      step,
      teamId: currentTeamId,
      procedures,
    });

    if (Object.keys(validationErrors).length !== 0) {
      setStepErrors(validationErrors);
      return;
    }

    setStepErrors(validationErrors);
    setShowsDuplicateModal(false);
    setShowsSaveStepSnippet(true);
  }, [currentTeamId, present.step, store]);

  const onAddStepHeader = () => {
    const stepHeader = procedureUtil.newStepHeader();
    const updated = cloneDeep(present);

    // Allow for backwards-compatibility if a step was created without a `headers` empty array
    if (!updated.step.headers) {
      updated.step.headers = [];
    }

    updated.step.headers.push(stepHeader);
    setPresent(updated);
  };

  // Removes the given step header.
  const onRemoveStepHeader = () => {
    const updated = cloneDeep(present);
    updated.step.headers?.pop();
    setPresent(updated);
  };

  const onSnippetStepChanged = useCallback(
    (values, sectionIndex: number, stepIndex: number) => {
      if (!present) {
        return;
      }

      /* Save Updated Snippet of type Step  */
      const updated = cloneDeep(present);
      const step = present.step;
      updated.step = {
        id: step.id,
        ...values,
      };

      if (isEqual(present, updated)) {
        return;
      }

      setPresent(updated);
    },
    [present]
  );

  const onSaveStepSnippet = useCallback(
    async (values) => {
      const updatedStep = cloneDeep(present.step);
      const snippetId = present.snippet_id;
      const name = values.name;
      const description = values.description;
      return attachmentUtil
        .uploadAllFilesFromStep(updatedStep, services.attachments)
        .then(() =>
          services.settings.saveStepSnippet({
            id: snippetId,
            name,
            description,
            step: updatedStep,
            isTestSnippet: testingModule,
          })
        )
        .then(() => {
          const updated = cloneDeep(present);
          updated.name = name;
          updated.description = description;

          setPresent(updated);
          setShowsSaveStepSnippet(false);

          // Go to edit after saving duplicate snippet
          if (showsDuplicateModal) {
            setShowsDuplicateModal(false);
            return;
          }
          onClose(); //Redirects to Snippet Settings page
        })
        .then(() => onSaveSuccess && onSaveSuccess())
        .catch(() => undefined);
    },
    [onClose, onSaveSuccess, present, services.attachments, services.settings, showsDuplicateModal, testingModule]
  );

  const shouldPrompt = useMemo(() => {
    // no prompt needed if no new snippet edits were made
    return !isEqual(present, snippet);
  }, [present, snippet]);

  const onContentMenuClick = (menuItem) => {
    switch (menuItem.action) {
      case Actions.AddStepHeader:
        return onAddStepHeader();
      case Actions.DeleteStepHeader:
        return onRemoveStepHeader();
      case Actions.CopyStep:
        return onCopyStep();
      case Actions.PasteBlock:
        return onPasteBlock();
      default:
        return;
    }
  };

  const onKeyboard = useCallback(
    (event) => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopyStep();
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteBlock();
      }
    },
    [onCopyStep, onPasteBlock]
  );

  const onCloseModal = useCallback(() => {
    if (showsDuplicateModal) {
      setShowsDuplicateModal(false);
      onClose();
    } else {
      setShowsSaveStepSnippet(false);
    }
  }, [showsDuplicateModal, onClose]);

  return (
    <>
      <SelectionContextProvider>
        <PromptBeforeUnload shouldPrompt={shouldPrompt} />
        {/* Save step snippet modal */}
        {(showsSaveStepSnippet || showsDuplicateModal) && (
          <ModalSaveSnippet
            onPrimaryAction={onSaveStepSnippet}
            onSecondaryAction={onCloseModal}
            currentName={present.name}
            currentDescription={present.description}
          />
        )}
        {/* Edit Header */}
        <FieldSetSnippetHeader
          present={present}
          snippet={snippet}
          onSave={onValidateStepSnippet}
          onClose={onClose}
          onRemove={onRemove}
          showSubmitError={Object.keys(stepErrors).length !== 0}
        />

        <Selectable boundary={Boundary.Step} block={present.step} onKeyboard={onKeyboard}>
          {({ styles }) => (
            <div aria-label="Step" role="region" className="flex flex-row mt-2 gap-x-3">
              <div className="flex flex-row gap-x-3">
                <AddContentMenu
                  menuItems={getStepAddContentItems({
                    hasStepHeader: (present.step?.headers?.length || 0) > 0,
                    canAddStep: false,
                    canInsertSnippet: false,
                    canDeleteStep: false,
                    canCopyStep: true,
                    canCutStep: false,
                    canPasteStep: false,
                    canPasteBlock: !!clipboardBlock,
                    canSaveAsSnippet: false,
                  })}
                  onClick={(menuItem) => onContentMenuClick(menuItem)}
                />
              </div>

              {/* Edit Snippet Step */}
              <div className={`w-full ${styles}`}>
                <ProcedureContextProvider procedure={procedureShell} scrollTo={undefined}>
                  <FieldSetProcedureStep
                    step={present.step}
                    isPending={true}
                    isStepHeadersEnabled={true}
                    errors={stepErrors}
                    onFieldRefChanged={() => undefined}
                    configurePartKitBlock={() => undefined}
                    configurePartBuildBlock={() => undefined}
                    onPartChanged={() => undefined}
                    onStepFormChanged={onSnippetStepChanged}
                    enabledContentTypes={[
                      BlockTypes.Alert,
                      BlockTypes.Text,
                      BlockTypes.Attachment,
                      BlockTypes.FieldInput,
                      BlockTypes.TableInput,
                      BlockTypes.ProcedureLink,
                      BlockTypes.Commanding,
                      BlockTypes.Telemetry,
                      BlockTypes.Requirement,
                      BlockTypes.ExternalItem,
                      ...(testingModule ? [BlockTypes.TestCases] : []),
                    ]}
                    stepIndex={undefined}
                    sectionIndex={undefined}
                    isCollapsed={isCollapsedMap[present.step.id]}
                    precedingStepId={undefined}
                    onStepCollapse={setIsCollapsed}
                    onAcceptRedlineField={undefined}
                    onRejectRedlineField={undefined}
                    onAcceptRedlineBlock={undefined}
                    onRejectRedlineBlock={undefined}
                    redlines={undefined}
                    changedStepIdSet={undefined}
                    setChangedStepIdSet={undefined}
                    onRemoveStepHeader={onRemoveStepHeader}
                    areDependenciesEnabled={false}
                  />
                </ProcedureContextProvider>
              </div>
            </div>
          )}
        </Selectable>
      </SelectionContextProvider>
      <FlashMessage message={flashMessage} messageUpdater={setFlashMessage} />
    </>
  );
};

export default FieldSetStepSnippet;
