import { useCallback, useMemo, useState } from 'react';
import { SelectColumn } from 'react-data-grid';
import Button from '../../components/Button';
import Label from '../../components/Label';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import Grid, { GridColumn, TextAlign } from '../../elements/Grid';
import useProjectFilterHelper from '../hooks/useProjectFilterHelper';
import { calculateAssessmentLevel } from '../libs/hazards';
import { TestCase } from '../types';
import AssessmentIndicator from './AssessmentIndicator';
import useTestingAuth from '../hooks/useTestingAuth';
import { ColumnOverrides, applyOverrides } from '../../lib/gridUtils';

const MAIN_VERTICAL_PADDING = 165;
const BASE_ROW_HEIGHT = 35;
const ADDITIONAL_ROW_HEIGHT = 24;

interface TestCasesTableProps {
  testCases: Array<TestCase>;
  selectedRows: ReadonlySet<string>;
  usedVerticalSpace?: number;
  columnOverrides?: ColumnOverrides;
  onClickedRow: (id: string) => void;
  onSelectedRowsChange: (selectedRows: Set<string>) => void;
}

const TestCasesTable = ({
  testCases,
  selectedRows,
  usedVerticalSpace = MAIN_VERTICAL_PADDING,
  columnOverrides,
  onClickedRow,
  onSelectedRowsChange,
}: TestCasesTableProps) => {
  const [allExpanded, setAllExpanded] = useState<boolean>(true);
  const { getProjectName } = useProjectFilterHelper();
  const { hasEditPermission } = useTestingAuth();

  const rowHeight = useCallback(
    (row: TestCase) => {
      return (
        BASE_ROW_HEIGHT +
        (allExpanded
          ? ADDITIONAL_ROW_HEIGHT * Math.max(1, row.test_case_conditions.length - 1, (row.hazards?.length ?? 1) - 1)
          : ADDITIONAL_ROW_HEIGHT)
      );
    },
    [allExpanded]
  );

  const rowKeyGetter = (row: TestCase) => {
    return row.id;
  };

  const onClickRow = useCallback(
    (id) => {
      onClickedRow(id);
    },
    [onClickedRow]
  );

  const testCasesColumns = useMemo<Array<GridColumn<TestCase>>>(() => {
    const baseColumns = [
      SelectColumn,
      {
        key: 'name',
        name: 'Test Point',
        sortable: true,
        width: '40%',
        renderCell({ row }: { row: TestCase }) {
          return <div className="text-sm text-gray-900">{row.name}</div>;
        },
      },
      {
        key: 'project_id',
        name: 'Project',
        sortable: true,
        renderCell({ row }: { row: TestCase }) {
          return row.project_id && <Label text={getProjectName(row.project_id) as string} color="bg-gray-200" />;
        },
        comparator: (a: TestCase, b: TestCase) => {
          return (getProjectName(a.project_id) ?? '').localeCompare(getProjectName(b.project_id) ?? '');
        },
      },
      {
        key: 'conditions',
        name: 'Conditions',
        sortable: false,
        width: '17%',
        renderCell({ row }: { row: TestCase }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.test_case_conditions.length > 1 && (
                <>
                  <div className="text-sm text-gray-900 flex flex-row gap-x-[2px]">
                    {`${row.test_case_conditions[0].name}: ${row.test_case_conditions[0].value}`}
                    <UnitDisplay unit={row.test_case_conditions[0].units} />
                  </div>
                  <div className="text-sm text-gray-900">...</div>
                </>
              )}
              {(allExpanded || row.test_case_conditions.length <= 1) &&
                row.test_case_conditions.map((condition) => (
                  <div key={condition.condition_id} className="text-sm text-gray-900 flex flex-row gap-x-[2px]">
                    {`${condition.name}: ${condition.value}`}
                    <UnitDisplay unit={condition.units} />
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        key: 'hazard',
        name: 'Hazard',
        sortable: true,
        width: '15.9%',
        renderCell({ row }: { row: TestCase }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.hazards && row.hazards.length > 1 && (
                <>
                  <div className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator hazard={row.hazards[0]} />
                    <div key={row.hazards[0].name} className="text-sm text-gray-900">
                      {row.hazards[0].name}
                    </div>
                  </div>
                  <div className="text-sm text-gray-900">...</div>
                </>
              )}
              {row.hazards &&
                (allExpanded || row.hazards.length <= 1) &&
                row.hazards.map((hazard) => (
                  <div key={hazard.name} className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator hazard={hazard} />
                    <div className="text-sm text-gray-900">{hazard.name}</div>
                  </div>
                ))}
            </div>
          );
        },
        comparator: (a: TestCase, b: TestCase) => {
          const hazardLevelA = a.hazards && a.hazards.length > 0 ? calculateAssessmentLevel(a.hazards[0]) : 0;
          const hazardLevelB = b.hazards && b.hazards.length > 0 ? calculateAssessmentLevel(b.hazards[0]) : 0;
          return hazardLevelA - hazardLevelB;
        },
      },
      {
        key: 'expand_collapse',
        name: (
          <Button
            type="tertiary"
            leadingIcon={allExpanded ? 'down-left-and-up-right-to-center' : 'expand-alt'}
            onClick={() => setAllExpanded(!allExpanded)}
          />
        ),
        align: TextAlign.Right,
        sortable: false,
        width: 100,
        renderCell({ row }: { row: TestCase }) {
          return !hasEditPermission(row.project_id) ? (
            <></>
          ) : (
            <div>
              <Button
                isDisabled={selectedRows.size > 0}
                type="tertiary"
                leadingIcon="pencil-alt"
                onClick={() => onClickRow(row.id)}
              >
                Edit
              </Button>
            </div>
          );
        },
      },
    ];
    if (columnOverrides) {
      return applyOverrides(baseColumns, columnOverrides);
    }
    return baseColumns;
  }, [allExpanded, getProjectName, hasEditPermission, columnOverrides, onClickRow, selectedRows.size]);

  return (
    <div className="mt-2">
      <Grid
        columns={testCasesColumns}
        rows={testCases}
        rowHeight={(row) => rowHeight(row as TestCase)}
        emptyRowMessage="No matching test points found"
        usedVerticalSpace={usedVerticalSpace}
        selectedRows={selectedRows}
        onSelectedRowsChange={onSelectedRowsChange}
        rowKeyGetter={rowKeyGetter}
      />
    </div>
  );
};

export default TestCasesTable;
