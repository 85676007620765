import React, { useEffect, useRef } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSettings } from '../contexts/SettingsContext';
import ContactSupport from '../components/ContactSupport';
import ProcedureGenerationWithAttachment from './screens/ProcedureGenerationWithAttachment';

const ProcedureGenerationRoute = () => {
  const isMounted = useRef(true);
  const { path } = useRouteMatch();
  const { isProcedureGenerationEnabled } = useSettings();

  // Update mounted flag when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  if (!isProcedureGenerationEnabled()) {
    return <ContactSupport />;
  }

  return (
    <div className="flex flex-grow">
      <div className="w-full p-4">
        <div className="flex flex-col gap-y-2 pb-4">
          <Switch>
            <Route exact path={`${path}`}>
              <ProcedureGenerationWithAttachment />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ProcedureGenerationRoute;
