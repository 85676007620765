import { Mention } from './types/postgres/util';
import { RunStepComment } from './types/views/procedures';
import lodash from 'lodash';

export const EDIT_COMMENT_ALLOWED_MINUTES_AFTER = 30;
export const EDIT_COMMENT_ERROR_MESSAGE = `The comment was created more than ${EDIT_COMMENT_ALLOWED_MINUTES_AFTER} minutes ago.`;

export interface CommentInterface {
  comment: string;
  mentions: Array<Mention>;
  edited: boolean;
  updated_at?: string;
}

export type Activity = {
  type: 'comment';
  timestamp: string;
  id?: string;
  updated_at?: string;
};

export type UserActivity = Activity & {
  user_id: string;
};

export type UserComment = UserActivity & {
  type: 'comment';
  comment: string;
  mentions: Mention[];
};

export function validateCanEditComment(
  createdAt: string,
  updatedAt: string
): void {
  const updatedTimeMillis = new Date(updatedAt).getTime();
  const commentTimeMillis = new Date(createdAt).getTime();

  const allowedTimeInMillis = EDIT_COMMENT_ALLOWED_MINUTES_AFTER * 60 * 1000;

  if (updatedTimeMillis - commentTimeMillis >= allowedTimeInMillis) {
    throw new Error(EDIT_COMMENT_ERROR_MESSAGE);
  }
}

export function wasEdited(created_at?: string, updated_at?: string): boolean {
  return Boolean(updated_at) && updated_at !== created_at;
}

export const commentToActivity = (comment: RunStepComment): UserComment => {
  return {
    ...lodash.omit(comment, ['text', 'user_id', 'mention_list']),
    type: 'comment',
    comment: comment.text,
    user_id: comment.user,
    mentions: comment.mention_list ?? [],
  } as UserComment;
};

export const activityToComment = (activity: UserComment): RunStepComment => {
  return {
    ...lodash.omit(activity, ['type', 'comment', 'user_id', 'mentions']),
    text: activity.comment,
    mention_list: activity.mentions,
    user: activity.user_id,
    parent_id: '',
  } as RunStepComment;
};
