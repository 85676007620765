import { useState, useEffect } from 'react';
import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import PartUsageTypeSelect from './PartUsageTypeSelect';
import InternalPartSelect from './PartAndRevisionSelectUsingParts';
import ExternalPartSelect from './ExternalPartSelect';
import { useSettings } from '../../contexts/SettingsContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import useParts from '../hooks/useParts';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

const TOOLTIP_MESSAGE = 'If unselected in edit, part for usage can be selected during the run';

const PartUsageFieldSet = ({ content, contentErrors, path, setFieldValue, isEnabled = true }) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isExternalPartsUsageEnabled } = useSettings();
  const { getPart } = useParts();
  const [externalUsageTypes, setExternalUsageTypes] = useState({
    loaded: false,
    types: [] as UsageType[],
  });

  useEffect(() => {
    if (!isExternalPartsUsageEnabled() || !services.manufacturing) {
      return;
    }
    if (externalUsageTypes.loaded) {
      return;
    }
    services.manufacturing.listExternalUsageTypes().then((types) => {
      setExternalUsageTypes({
        loaded: true,
        types,
      });
    });
  }, [isExternalPartsUsageEnabled, services.manufacturing, externalUsageTypes]);

  const pathToSet = path || '';

  const onChangePart = (part: Part | null) => {
    if (part) {
      const contentToSet = {
        ...content,
        part,
        part_id: part?.id || '',
        usage_types: [],
      };
      setFieldValue(pathToSet, contentToSet);
    } else {
      const contentToSet = {
        ...content,
        part: null,
        part_id: '',
        usage_types: [],
      };
      setFieldValue(pathToSet, contentToSet);
    }
  };

  const usageTypes: Array<UsageType> = [];
  if (content?.part?.usage_types?.length > 0) {
    usageTypes.push(...content.part.usage_types);
  }
  if (isExternalPartsUsageEnabled() && externalUsageTypes.loaded) {
    usageTypes.push(...externalUsageTypes.types);
  }

  const onChangeUsageType = (option) => {
    if (!option) {
      return;
    }
    const usageType = usageTypes.find((t) => t.name === option.value);
    const contentToSet = {
      ...content,
      usage_types: [usageType],
    };
    setFieldValue(pathToSet, contentToSet);
  };

  let usageTypeSelected = null;
  if (content && content.usage_types && content.usage_types.length > 0) {
    usageTypeSelected = content.usage_types[0];
  }

  // if showing an already selected part that the user does not have access to
  if (content.part !== null && isPartRestricted(getPart(content.part_id))) {
    return (
      <div className="mt-2">
        <RestrictedInfo text={RESTRICTED_TEXT} />
      </div>
    );
  }

  return (
    <div className="flex grow flex-wrap gap-x-2 items-end">
      <div className="flex flex-col">
        {isExternalPartsUsageEnabled() && <ExternalPartSelect part={content?.part} onChangePart={onChangePart} />}
        {!isExternalPartsUsageEnabled() && (
          <InternalPartSelect
            selectedPart={content?.part}
            onChangePart={onChangePart}
            isEnabled={isEnabled}
            partSelectLabel="Part for Recording Usage"
            partSelectToolTip={TOOLTIP_MESSAGE}
          />
        )}
        {contentErrors && contentErrors.part && <div className="text-sm text-red-700">{contentErrors.part}</div>}
      </div>
      <div className="flex flex-row space-x-1">
        <div className="w-56">
          <span className="field-title">Usage Type</span>
          <PartUsageTypeSelect
            selected={usageTypeSelected}
            usageTypes={usageTypes}
            onChange={onChangeUsageType}
            canCreate={false}
            isEnabled={isEnabled}
          />
        </div>
        {contentErrors && contentErrors.usage_types && contentErrors.usage_types.length > 0 && (
          <div className="text-sm text-red-700 mt-8"> {contentErrors.usage_types[0]}</div>
        )}
      </div>
      {/* Include in summary checkbox */}
      <div className="self-end mb-2 flex flex-row items-center">
        <FieldSetCheckbox
          text="Include in Summary"
          fieldName={`${path}.include_in_summary`}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default PartUsageFieldSet;
