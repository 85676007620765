const SUPPORTED_OPERATIONS = ['=', '≠', '<', '>', '≤', '≥'];

const isSupportedOperation = (op: string): boolean => {
  return SUPPORTED_OPERATIONS.includes(op);
};

/**
 * Evaluates math expression of the form "lhs op rhs."
 *
 * E.g., lhs = 1, rhs = 2, and op = '<', evaluates to true.
 *
 * @param lhs - left hand side of the expression
 * @param rhs - right hand side of the expression
 * @param op - math operation to perform
 *
 * @returns true if the expression is true
 */
const evaluate = (
  lhs: number | string | boolean,
  rhs: number | string | boolean,
  op: string
): boolean | null => {
  if (op === '=') {
    return lhs === rhs;
  } else if (op === '≠') {
    return lhs !== rhs;
  } else if (op === '<') {
    return lhs < rhs;
  } else if (op === '>') {
    return lhs > rhs;
  } else if (op === '≤') {
    return lhs <= rhs;
  } else if (op === '≥') {
    return lhs >= rhs;
  }
  return null;
};

type Range = {
  min: number;
  max: number;
};

/**
 * Evaluates if value lies in range, excluding the endpoints of
 * the range.
 *
 * E.g., value = 1, min = 0, max = 1 evaluates to false.
 *
 * @param value - value to check
 * @param range - range to check
 * @param range.min - lower bound
 * @param range.max - upper bound
 *
 * returns true if value lies in range
 */
const evaluateRangeExclusive = (
  value: number | string | boolean,
  range: Range
): boolean => {
  return value > range.min && value < range.max;
};

export { isSupportedOperation, evaluate, evaluateRangeExclusive };
