import { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import EditSelectClearIndicator from '../../components/EditSelectClearIndicator';
import ItemOption from './ItemOption';
import { getSerialItemTopLabel } from '../lib/items';
import { Item } from '../types';

type InputAction = {
  action: string;
};

type ItemOptionType = {
  value: string;
  label: string;
  amountLabel: string;
  item: Partial<Item>;
};

type SelectData = {
  options: Array<ItemOptionType>;
  isLoading: boolean;
  inputValue: string;
};

type ItemSelectProps = {
  item?: Partial<Item>;
  partNo?: string;
  minAmount?: number;
  onChangeItem?: (Item) => void;
  isDisabled?: boolean;
};

const ExternalItemSelect = ({ item, partNo, onChangeItem, isDisabled }: ItemSelectProps) => {
  const { services } = useDatabaseServices();
  const [selectData, setSelectData] = useState<SelectData>({
    options: [],
    isLoading: false,
    inputValue: '',
  });

  const itemToOption = useCallback((item: Partial<Item> | undefined): ItemOptionType | null => {
    if (!item || !item.serial) {
      return null;
    }

    return {
      value: item.serial,
      label: getSerialItemTopLabel(item),
      amountLabel: 'Amount: 1', // all serial tracked parts have quantity 1
      item,
    };
  }, []);

  const selectValue = useMemo((): ItemOptionType | null => itemToOption(item), [itemToOption, item]);

  const onChange = (option: ItemOptionType): void => {
    if (!onChangeItem) {
      return;
    }
    if (!option || !option.item) {
      onChangeItem(null);
      return;
    }
    // Skip onChange when re-selected the same value.
    if (item && item.id === option.item.id) {
      return;
    }
    onChangeItem(option.item);
  };

  const searchItemOptions = useCallback(
    (query: string): Promise<Array<ItemOptionType>> => {
      if (!services.manufacturing) {
        return Promise.resolve([]);
      }
      return services.manufacturing
        .searchItemsByPartNo(partNo, query)
        .then((items) => items.map((item) => itemToOption(item)));
    },
    [services.manufacturing, partNo, itemToOption]
  );

  const loadOptions = useCallback(
    (inputValue: string): void => {
      searchItemOptions(inputValue).then((options) => {
        setSelectData((data) => {
          if (data.inputValue !== inputValue) {
            return data;
          }
          return {
            options,
            inputValue,
            isLoading: false,
          };
        });
      });
    },
    [searchItemOptions]
  );

  const debouncedLoadOptions = useMemo(() => debounce((query) => loadOptions(query), 300), [loadOptions]);

  const loadOptionsHandler = useCallback(
    (inputValue) => {
      setSelectData({
        options: [],
        inputValue,
        isLoading: true,
      });
      debouncedLoadOptions(inputValue);
    },
    [debouncedLoadOptions]
  );

  const onInputChange = useCallback(
    (inputValue: string, { action }: InputAction): void => {
      // See https://react-select.com/props#statemanager-props
      if (action !== 'input-change') {
        return;
      }
      loadOptionsHandler(inputValue);
    },
    [loadOptionsHandler]
  );

  const onMenuOpen = useCallback(() => loadOptionsHandler(''), [loadOptionsHandler]);

  return (
    <div className="w-56">
      <Select
        styles={reactSelectStyles}
        classNamePrefix="react-select"
        components={{ Option: ItemOption, ClearIndicator: EditSelectClearIndicator }}
        isLoading={selectData.isLoading}
        options={selectData.isLoading ? [] : selectData.options}
        onChange={onChange}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        placeholder="Search inventory"
        value={selectValue}
        isClearable={true}
        defaultOptions
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ExternalItemSelect;
