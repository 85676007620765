import { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  Point,
  ChartData,
  ChartTypeRegistry,
  BubbleDataPoint,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import Checkbox from '../../components/Checkbox';
import { Bounds, Measurement, Measurements, Selection, SelectionType, Point as SeriesPoint } from '../types';
import ZoomControl from './ZoomControl';
import XAxisSelect from '../components/XAxisSelect';
import { getMinTimeBetweenMeasurements, getIsoTimeSeconds, getIsoTimeMicroseconds } from '../lib/time';
import { Annotation, DataType } from '../api/annotation';
import useStorageTrack from '../hooks/useStorageTrack';
import AnnotateDataModal from './AnnotateDataModal';
import ButtonIcon from './ButtonIcon';
import { Rule } from 'shared/lib/types/views/procedures';
import chartColorsLib from '../lib/chartColors';
import { EnumParameter } from '../screens/Storage';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import useUnits from '../../hooks/useUnits';
import { Dictionary } from '../../lib/models/postgres/dictionary';
import { DEFAULT_DICTIONARY_NAME } from 'shared/lib/types/telemetry';
import Button, { BUTTON_TYPES } from '../../components/Button';
import getCsvArray, { CsvArray, getCsvFilename } from '../lib/csvUtil';
import { CSVLink } from 'react-csv';
import useChartPlugin from '../lib/chartJsPlugin';
import TooltipComponent from '../../elements/Tooltip';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import useStorageAuth from '../hooks/useStorageAuth';

export const X_AXIS_VALUES = {
  UNIX: 'unix',
  ISO: 'iso',
};

const X_AXIS_OPTIONS = [
  {
    value: X_AXIS_VALUES.ISO,
    label: 'Date/Time',
  },
  {
    value: X_AXIS_VALUES.UNIX,
    label: 'Time (ms)',
  },
];

// Delay to let scroll zoom finish before upsampling
const UPSAMPLE_DELAY_MS = 100;

export const DEFAULT_ZOOM_LEVEL = 1;
const DEFAULT_ZOOM_INCREMENT = 0.1;

const TELEMETRY_RULE_PADDING = 10;

// Minimum time between measurements to determine if showing microseconds is appropriate, in milliseconds
const TIME_DIFF_THRESHOLD_TO_SHOW_MICROSECONDS = 1;

// Downsample to display every nth xtick
const SAMPLE_XTICK_RATE = 2;

const BOTTOM_PADDING = 20;
const DEFAULT_ASPECT_RATIO = 2.5;
const HEIGHT_PERCENTAGE_GOLDEN_RATIO = '38%';

const THIRTY_DAYS_AGO_MS = 30 * 24 * 60 * 60 * 1000;
const REFRESH_RATE_MS = 1000; // refreshes every second

const DOWNLOAD_MULTIPLE_NOT_SUPPORTED_MESSAGE = `
  Downloading a CSV with signals from multiple tables is not
  currently supported.`;

const SELECTION_TO_MEASUREMENT_TYPES = {
  [SelectionType.Upload]: DataType.Upload,
  [SelectionType.RunTelemetry]: DataType.RunTelemetry,
  [SelectionType.Parameter]: DataType.Parameter,
};

const findMinMaxInMeasurements = (measurementData: SeriesPoint[]): { min: number | null; max: number | null } => {
  if (measurementData.length === 0) {
    return { min: null, max: null };
  }

  let min = measurementData[0].value;
  let max = measurementData[0].value;
  for (let i = 1; i < measurementData.length; i++) {
    const value = measurementData[i].value;
    if (value < min) {
      min = value;
    }
    if (value > max) {
      max = value;
    }
  }
  return { min, max };
};

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale, zoomPlugin);

export interface ExtendedChartData
  extends ChartData<keyof ChartTypeRegistry, (number | [number, number] | Point | BubbleDataPoint | null)[], unknown> {
  measurements?: Measurement[];
  annotations?: Annotation[];
  enabledAction?: ChartAction;
  annotationEnabled?: boolean;
}

export interface AnnotateModalState {
  clientX: number;
  clientY: number;
  isCreating: boolean;
  visible: boolean;
  xValue?: number;
  channel?: string;
  type: DataType;
  identifier?: number | string;
  annotation?: Annotation;
}

export enum PlotType {
  RunPlot,
  RunModalPlot,
  'Data&AnalysisPlot',
}

export enum ChartAction {
  Zoom = 'zoom',
  Pan = 'pan',
  Annotate = 'annotate',
}

type Link = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

interface PlotProps {
  selections: Selection[];
  evaluatedExpression?: Measurement | null;
  colorOverride?: string;
  bounds: Bounds;
  plotType: PlotType;
  setBounds?: (bounds: Bounds) => void;
  initialBounds?: Bounds;
  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;
  xAxis?: string;
  setXAxis?: (xAxis: string) => void;
  canChangeXAxis: boolean;
  telemetryRule?: Rule;
  dictionaries: Dictionary[];
  maintainAspectRatio?: boolean;
  annotationsVisible?: boolean;
  pauseRefreshPlot?: boolean;
}

const Plot = ({
  colorOverride,
  selections,
  evaluatedExpression = null,
  bounds,
  plotType,
  setBounds,
  initialBounds,
  zoomLevel = DEFAULT_ZOOM_LEVEL,
  setZoomLevel = () => {
    /* no-op */
  },
  xAxis = X_AXIS_VALUES.ISO,
  setXAxis = () => {
    /* no-op */
  },
  canChangeXAxis,
  telemetryRule,
  dictionaries,
  maintainAspectRatio = true,
  annotationsVisible = true,
  pauseRefreshPlot = false,
}: PlotProps) => {
  const plotRef = useRef<ChartJS<'scatter'>>(null);
  const timerRef = useRef<number | null>(null);
  const intervalId = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const csvLink = useRef<Link>(null);
  const [isMultiY, setIsMultiY] = useState(false);
  const [annotateModal, setAnnotateModal] = useState<AnnotateModalState>({
    clientX: 0,
    clientY: 0,
    isCreating: false,
    visible: false,
    type: DataType.RunTelemetry,
  });
  const [enabledAction, setEnabledAction] = useState<ChartAction>(ChartAction.Zoom);
  const [isCmdCtrlPressed, setIsCmdCtrlPressed] = useState(false);
  const [measurements, setMeasurements] = useState<Measurements>([]);
  const [enumTelemetryParameters, setEnumTelemetryParameters] = useState<EnumParameter[]>([]);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [csvData, setCsvData] = useState<CsvArray>([]);

  const storageTrack = useStorageTrack();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { addUnitsToLabel } = useUnits();
  const { hasEditPermission } = useStorageAuth();

  const onRun = useMemo(() => plotType === PlotType.RunModalPlot || plotType === PlotType.RunPlot, [plotType]);
  const onDataAndAnalysis = useMemo(() => plotType === PlotType['Data&AnalysisPlot'], [plotType]);
  const onRunModalPlot = useMemo(() => plotType === PlotType.RunModalPlot, [plotType]);
  const panEnabled = useMemo(
    () => (onDataAndAnalysis || onRunModalPlot) && !isCmdCtrlPressed && enabledAction === ChartAction.Pan,
    [enabledAction, isCmdCtrlPressed, onDataAndAnalysis, onRunModalPlot]
  );
  const dragEnabled = useMemo(
    () => (onDataAndAnalysis || onRunModalPlot) && !isCmdCtrlPressed && enabledAction === ChartAction.Zoom,
    [enabledAction, isCmdCtrlPressed, onDataAndAnalysis, onRunModalPlot]
  );
  const annotationEnabled = useMemo(
    () => hasEditPermission && annotationsVisible,
    [hasEditPermission, annotationsVisible]
  );
  const showZoomControl = useMemo(
    () => plotType === PlotType['Data&AnalysisPlot'] || plotType === PlotType.RunModalPlot,
    [plotType]
  );

  const plugin = useChartPlugin(storageTrack, telemetryRule, enabledAction, plotType, setAnnotateModal);

  useEffect(() => {
    if (!annotationsVisible) {
      setEnabledAction(ChartAction.Zoom);
    }
  }, [annotationsVisible]);

  const getMeasurement = useCallback(
    (selection: Selection, series: SeriesPoint[], isDownsampled: boolean): Measurement => {
      const measurementType = SELECTION_TO_MEASUREMENT_TYPES[selection.type];
      return {
        name: addUnitsToLabel(selection.measurement, selection.units),
        bucket: selection.bucket,
        series,
        isDownsampled,
        folderId: selection.folder_id,
        type: measurementType,
      };
    },
    [addUnitsToLabel]
  );

  const refreshAnnotations = useCallback(() => {
    const annotationIdentifiers = new Set();
    const annotationPromises = selections.map((selection) => {
      const folderId = selection.folder_id;
      if (annotationIdentifiers.has(folderId)) {
        return Promise.resolve([]);
      }
      annotationIdentifiers.add(folderId);

      if (selection.type === SelectionType.RunTelemetry) {
        return services.annotation.getAnnotationsForRunTelemetryData(folderId as string);
      } else if (selection.type === SelectionType.Upload) {
        return services.annotation.getAnnotationsForUploadedData(folderId as number);
      } else {
        return services.annotation.getAnnotationsForParameterData(folderId as string);
      }
    });

    Promise.all(annotationPromises).then((annotations) => {
      const allAnnotations = ([] as Annotation[]).concat(...annotations);
      setAnnotations(allAnnotations);
    });
  }, [selections, services]);

  const refreshPlot = useCallback(async () => {
    if (!services.influx) {
      return;
    }
    // Get time series for the selected measurements
    const promises = selections.map((selection) => {
      const bucket = selection.bucket;
      const measurement = selection.measurement;
      let boundsFromSelection;
      if (selection.type === SelectionType.Parameter) {
        if (bounds.min === -Infinity && bounds.max === Infinity) {
          const thirtyDaysAgo = new Date(new Date().getTime() - THIRTY_DAYS_AGO_MS);
          const thirtyDaysAgoUnix = Math.floor(thirtyDaysAgo.getTime());
          boundsFromSelection = {
            min: thirtyDaysAgoUnix,
            max: null,
          };
        } else {
          boundsFromSelection = bounds;
        }
      } else {
        boundsFromSelection = selection.bounds ?? bounds;
      }

      return services.influx.getSeries(bucket, measurement, boundsFromSelection);
    });
    Promise.all(promises)
      .then((res) =>
        res.map((res, index) => {
          const series = res.series;
          const isDownsampled = res.is_downsampled;
          return getMeasurement(selections[index], series, isDownsampled);
        })
      )
      .then((updated) => setMeasurements(updated))
      .catch(() => {
        /* no-op */
      });

    // Get annotations for the selected measurements
    refreshAnnotations();
  }, [bounds, getMeasurement, refreshAnnotations, selections, services.influx]);

  useEffect(() => {
    refreshPlot();
  }, [refreshPlot, selections]);

  // Check if the buckets of any of the plotted measurements match the name of a telemetry dictionary
  const isTelemetryShown = useCallback(async () => {
    if (measurements.length === 0) {
      return false;
    }

    const dictionaryNames = dictionaries.map((d) => d.name).concat([DEFAULT_DICTIONARY_NAME]);
    return measurements.some((m) => dictionaryNames.some((name) => name === m.bucket));
  }, [dictionaries, measurements]);

  const selectionsNeedRefresh = useCallback(() => {
    return selections.some(
      (selection) => (selection.bounds && !selection.bounds.max) || selection.type === SelectionType.Parameter
    );
  }, [selections]);

  // Refresh screen at an interval to show new telemetry points
  useEffect(() => {
    intervalId.current = setInterval(async () => {
      // Optimization-- don't refresh if no telemetry data is shown or if no selections are fetching new data
      if (!(await isTelemetryShown()) || !selectionsNeedRefresh() || pauseRefreshPlot) {
        return;
      }
      refreshPlot();
    }, REFRESH_RATE_MS);
    return () => clearInterval(intervalId.current);
  }, [refreshPlot, isTelemetryShown, selectionsNeedRefresh, pauseRefreshPlot]);

  const displayMeasurements = useMemo(() => {
    if (evaluatedExpression) {
      return measurements.concat([evaluatedExpression]);
    }
    return measurements;
  }, [measurements, evaluatedExpression]);

  const uniqueBuckets = useMemo(() => {
    const displayBuckets = displayMeasurements.map((m) => m.bucket);
    return [...new Set(displayBuckets)];
  }, [displayMeasurements]);

  const onDownload = () => {
    if (uniqueBuckets.length === 0) {
      // should not get here
      return;
    }
    const csvData = getCsvArray(uniqueBuckets[0], measurements, bounds);
    setCsvData(csvData);
    storageTrack('Data Downloaded');
  };

  const csvFilename = useMemo(() => {
    return getCsvFilename(measurements);
  }, [measurements]);

  useEffect(() => {
    if (!csvLink.current || !csvData || csvData.length === 0) {
      return;
    }
    csvLink.current.link.click();
  }, [csvData]);

  const anyDownsampled = useMemo(() => {
    return displayMeasurements.some((m) => m.isDownsampled);
  }, [displayMeasurements]);

  const isDownloadEnabled = useMemo(() => {
    return selections.length > 0 && !anyDownsampled && uniqueBuckets.length === 1;
  }, [selections.length, anyDownsampled, uniqueBuckets.length]);

  const downloadCsvTooltip = useMemo(() => {
    if (anyDownsampled) {
      return 'Downloading CSV not supported for downsampled data';
    }
    if (uniqueBuckets.length > 1) {
      return DOWNLOAD_MULTIPLE_NOT_SUPPORTED_MESSAGE;
    }
    return 'Download CSV';
  }, [anyDownsampled, uniqueBuckets.length]);

  const fetchEnumTelemetryParameters = useCallback(async () => {
    const telemetryParameters = await services.telemetry.listAllParameters();
    const enumParameters: EnumParameter[] = [];
    for (const parameter of telemetryParameters) {
      if (parameter.type === 'enum') {
        enumParameters.push({
          name: parameter.name,
          values: parameter.values,
          dictionary_name:
            parameter.dictionary_id === 1
              ? 'Default'
              : (dictionaries.find((dict) => dict.id === parameter.dictionary_id) || {}).name || '',
        });
      }
    }
    setEnumTelemetryParameters(enumParameters);
  }, [dictionaries, services.telemetry]);

  const yAxisTickLabels = useCallback(
    (value: number): string | number => {
      for (const measurement of measurements) {
        const enumParameter = enumTelemetryParameters.find((telem) => telem.name === measurement.name);
        if (enumParameter && value in enumParameter.values) {
          return enumParameter.values[value];
        }
      }
      return value;
    },
    [enumTelemetryParameters, measurements]
  );

  useEffect(() => {
    fetchEnumTelemetryParameters();
  }, [fetchEnumTelemetryParameters]);

  useEffect(() => {
    if (plotRef.current) {
      const chart = plotRef.current;
      const chartData = chart.data as ExtendedChartData;
      chartData.measurements = displayMeasurements;
      chartData.annotations = annotationsVisible ? annotations : [];
      chartData.annotationEnabled = annotationEnabled;
      chartData.enabledAction = enabledAction;
      chart.update();
    }
  }, [displayMeasurements, annotations, enabledAction, annotationsVisible, annotationEnabled]);

  // Reset multiple y axes with one measurement
  useEffect(() => {
    if (displayMeasurements.length === 1 && isMultiY) {
      setIsMultiY(false);
    }
  }, [displayMeasurements, isMultiY]);

  useEffect(() => {
    const downHandler = (e) => {
      if (e.metaKey || e.ctrlKey) {
        setIsCmdCtrlPressed(true);
      }
    };

    const upHandler = (e) => {
      if (e.key === 'Meta' || e.key === 'Control') {
        setIsCmdCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  const onChangeXAxis = (option) => setXAxis(option.value);

  const onZoomComplete = useCallback(
    ({ chart }: { chart: ChartJS }) => {
      if (!setBounds || !plotRef.current) {
        return;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => {
        const { min, max } = chart.scales.x;
        setBounds({
          min,
          max,
        });
      }, UPSAMPLE_DELAY_MS);
    },
    [setBounds]
  );

  const onPanComplete = useCallback(
    ({ chart }) => {
      if (!setBounds || !plotRef.current) {
        return;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => {
        const { min, max } = chart.scales.x;
        setBounds({
          min,
          max,
        });
      }, UPSAMPLE_DELAY_MS);
    },
    [setBounds]
  );

  const zoomOptions = useMemo(() => {
    return {
      pan: {
        enabled: panEnabled,
        mode: 'x',
        onPanComplete,
      },
      zoom: {
        wheel: { enabled: onDataAndAnalysis },
        drag: {
          enabled: dragEnabled,
        },
        pinch: { enabled: onDataAndAnalysis },
        mode: 'x',
        onZoomComplete,
      },
    };
  }, [panEnabled, onPanComplete, onDataAndAnalysis, dragEnabled, onZoomComplete]);

  const resetZoom = () => {
    if (!setBounds || !plotRef.current) {
      return;
    }
    setBounds(
      initialBounds ?? {
        min: -Infinity,
        max: Infinity,
      }
    );
    setZoomLevel(DEFAULT_ZOOM_LEVEL);
  };

  const zoomIn = () => {
    if (!plotRef.current) {
      return;
    }
    const percentageZoom = DEFAULT_ZOOM_INCREMENT / zoomLevel;
    _updateZoom(DEFAULT_ZOOM_LEVEL + percentageZoom, zoomLevel + DEFAULT_ZOOM_INCREMENT);
  };

  const zoomOut = () => {
    if (!plotRef.current || zoomLevel === DEFAULT_ZOOM_LEVEL) {
      return;
    }
    if (zoomLevel - DEFAULT_ZOOM_INCREMENT <= DEFAULT_ZOOM_LEVEL) {
      resetZoom();
      return;
    }
    const percentageZoom = DEFAULT_ZOOM_INCREMENT / zoomLevel;
    _updateZoom(DEFAULT_ZOOM_LEVEL - percentageZoom, zoomLevel - DEFAULT_ZOOM_INCREMENT);
  };

  const _updateZoom = (plotRefZoom: number, newZoomLevel: number) => {
    if (!plotRef.current || !setBounds) {
      return;
    }

    plotRef.current.zoom(plotRefZoom);
    setZoomLevel(newZoomLevel);

    const { min, max } = plotRef.current.scales.x;
    setBounds({
      min,
      max,
    });
  };

  const getDataPoint = (point) => ({
    x: point.timestamp,
    y: point.value,
  });

  const getData = (measurement) => {
    return measurement.series.map(getDataPoint);
  };

  const datasets = displayMeasurements.map((measurement, index) => {
    const data = getData(measurement);
    const label = `${measurement.bucket}.${measurement.name}`;
    const color = colorOverride || chartColorsLib.getSelectionColor(index);

    // Plot on the same vertical axis if isMultiY not toggled
    const yAxisID = isMultiY ? label : 'y';

    return {
      data,
      showLine: true,
      label,
      borderWidth: 1,
      pointRadius: 2,
      borderColor: color,
      backgroundColor: color,
      yAxisID,
    };
  });

  const minTimeBetweenMeasurements = useMemo(
    () => getMinTimeBetweenMeasurements(displayMeasurements),
    [displayMeasurements]
  );

  const downsampleXTick = useCallback((label, index) => {
    if (index % SAMPLE_XTICK_RATE === 0) {
      return label;
    }
    return '';
  }, []);

  const convertXTickToIso = useCallback(
    (label) => {
      if (!label) {
        return '';
      }
      const showMicroseconds = minTimeBetweenMeasurements < TIME_DIFF_THRESHOLD_TO_SHOW_MICROSECONDS;
      if (showMicroseconds) {
        return getIsoTimeMicroseconds(label);
      }
      return getIsoTimeSeconds(label);
    },
    [minTimeBetweenMeasurements]
  );

  const convertXTickToIsoAndDownsample = useCallback(
    (label, index) => {
      const isoLabel = convertXTickToIso(label);
      return downsampleXTick(isoLabel, index);
    },
    [convertXTickToIso, downsampleXTick]
  );

  const labelXTick = useMemo(() => {
    if (xAxis === X_AXIS_VALUES.ISO) {
      return convertXTickToIsoAndDownsample;
    }
    return downsampleXTick;
  }, [xAxis, convertXTickToIsoAndDownsample, downsampleXTick]);

  const scales = useMemo(() => {
    const xAxisOptionSelected = X_AXIS_OPTIONS.find((option) => option.value === xAxis);
    const xAxisLabel = onRun ? '' : xAxisOptionSelected?.label;

    const res = {
      x: {
        ticks: {
          callback: labelXTick,
          ...(onRun && { maxRotation: 0 }),
        },
        title: {
          display: true,
          type: 'time',
          text: xAxisLabel,
        },
        ...(bounds.min !== -Infinity && { min: bounds.min }),
        ...(bounds.max !== Infinity && { max: bounds.max }),
      },
    };

    if (isMultiY) {
      displayMeasurements.forEach((measurement, index) => {
        const label = `${measurement.bucket}.${measurement.name}`;
        const color = colorOverride || chartColorsLib.getSelectionColor(index);
        res[label] = {
          title: {
            display: true,
            text: label,
            color,
          },
          ticks: { color, callback: yAxisTickLabels },
          position: 'left',
          display: true,
        };
      });
    } else {
      res['y'] = {
        ticks: {
          callback: yAxisTickLabels,
        },
      };
      // adjust the plot min/max to always show the telemetry rule
      if (telemetryRule && displayMeasurements.length > 0) {
        const measurementData = displayMeasurements[0].series;
        const { min, max } = findMinMaxInMeasurements(measurementData);

        const ruleValue = Number(telemetryRule.value);
        const yAxisMin = (min ? Math.min(ruleValue, min) : ruleValue) - TELEMETRY_RULE_PADDING;
        const yAxisMax = (max ? Math.max(ruleValue, max) : ruleValue) + TELEMETRY_RULE_PADDING;
        res['y'].min = yAxisMin;
        res['y'].max = yAxisMax;
      }
    }
    return res;
  }, [
    onRun,
    labelXTick,
    bounds.min,
    bounds.max,
    isMultiY,
    xAxis,
    displayMeasurements,
    yAxisTickLabels,
    telemetryRule,
    colorOverride,
  ]);

  const options = useMemo(() => {
    return {
      ...(onRun && { layout: { padding: { bottom: -BOTTOM_PADDING } } }),
      ...(maintainAspectRatio && { aspectRatio: DEFAULT_ASPECT_RATIO }),
      animation: false,
      plugins: {
        legend: { display: onDataAndAnalysis },
        colors: { enabled: false },
        zoom: zoomOptions,
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label;
              const xValue = context.parsed.x;
              const xFormatted = xAxis === X_AXIS_VALUES.UNIX ? xValue : convertXTickToIso(new Date(xValue));
              const yValue = context.parsed.y;

              return `${label}: (${xFormatted}, ${yValue})`;
            },
          },
        },
      },
      scales,
    } as ChartOptions<'scatter'>;
  }, [zoomOptions, scales, xAxis, convertXTickToIso, onRun, maintainAspectRatio, onDataAndAnalysis]);

  const data = { datasets, displayMeasurements };
  const plugins = [plugin];
  const initialPlotHeight = maintainAspectRatio ? undefined : HEIGHT_PERCENTAGE_GOLDEN_RATIO; // undefined means height is determined by the default aspect ratio

  if (displayMeasurements.length === 0) {
    return <div className="flex items-center justify-center mt-4">No data to display</div>;
  }

  return (
    <div
      className="flex flex-col rounded-lg p-4 border border-solid border-gray-200"
      style={{
        boxShadow: onRun ? '' : '0px 0px 16px rgba(0, 0, 0, 0.1)',
      }}
    >
      <CSVLink
        data={csvData}
        filename={csvFilename}
        className="hidden"
        ref={csvLink}
        target="_blank"
        rel="noopener noreferrer"
      />
      <div className="flex flex-row items-center justify-between text-sm">
        {canChangeXAxis && <XAxisSelect selectedValue={xAxis} options={X_AXIS_OPTIONS} onChange={onChangeXAxis} />}
        {onDataAndAnalysis && (
          <TooltipComponent content={downloadCsvTooltip}>
            <div className="justify-end">
              <Button
                type={BUTTON_TYPES.TERTIARY}
                size="sm"
                leadingIcon="download"
                onClick={onDownload}
                isDisabled={!isDownloadEnabled}
                ariaLabel="Download CSV Button"
              />
            </div>
          </TooltipComponent>
        )}
      </div>
      <Scatter
        key={enabledAction}
        ref={plotRef}
        options={options}
        data={data}
        plugins={plugins}
        height={initialPlotHeight}
      />
      {annotationsVisible && (
        <AnnotateDataModal
          annotateModal={annotateModal}
          hideModal={() =>
            setAnnotateModal((prevState) => ({
              ...prevState,
              visible: false,
            }))
          }
          refreshAnnotations={refreshAnnotations}
        />
      )}
      <div className="flex flex-row items-center justify-between">
        {(onDataAndAnalysis || onRunModalPlot) && (
          <div className="ml-4 flex">
            <ButtonIcon
              onClick={() => setEnabledAction(ChartAction.Zoom)}
              iconName="vector-square"
              tooltip="Zoom"
              isSelected={enabledAction === ChartAction.Zoom}
            />
            <ButtonIcon
              onClick={() => setEnabledAction(ChartAction.Pan)}
              iconName="arrows-left-right-to-line"
              tooltip="Pan"
              isSelected={enabledAction === ChartAction.Pan}
            />
            {annotationEnabled && (
              <ButtonIcon
                onClick={() => setEnabledAction(ChartAction.Annotate)}
                iconName="pen-to-square"
                tooltip="Annotate (Cmd/Ctrl+Click)"
                isSelected={enabledAction === ChartAction.Annotate}
              />
            )}
          </div>
        )}
        <div className="ml-2 font-medium italic text-gray-500 text-sm whitespace-nowrap">
          {anyDownsampled ? 'Displaying downsampled data' : null}
        </div>
        <div className="flex flex-row items-center gap-x-4">
          {displayMeasurements.length > 1 && (
            <Checkbox
              label="Multi Y-Axis"
              ariaLabel="Multi Y-Axis"
              checked={isMultiY}
              onClick={() => setIsMultiY(!isMultiY)}
              disabled={displayMeasurements.length < 2}
            />
          )}
          {showZoomControl && <ZoomControl zoomIn={zoomIn} zoomOut={zoomOut} resetZoom={resetZoom} />}
        </div>
      </div>
    </div>
  );
};

export default Plot;
