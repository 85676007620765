import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import FileStagingManager, { FileStagingManagerRef } from '../../components/FileStagingManager';
import FlashMessage from '../../components/FlashMessage';
import { SourceTypeEnum } from 'shared/lib/types/attachments';

type FormData = {
  code: string;
  version: string;
};

const ACCEPTED_FILE_TYPES = ['application/pdf'];

const ProcedureGeneration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { services: databaseServices } = useDatabaseServices();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { code: '', version: '1.1' } });
  const [globalErrors, setGlobalErrors] = useState<Error>();
  const fileStagingManagerRef = useRef<FileStagingManagerRef>(null);

  const onGenerate: SubmitHandler<FormData> = async (values) => {
    const attachments = fileStagingManagerRef.current?.getCurrentFles();
    try {
      setIsLoading(true);
      if (!attachments || attachments.length < 1) {
        setGlobalErrors(new Error('Must add an attachment'));
        return;
      }

      await databaseServices.ml.processProcedureAttachment({
        file: attachments[0],
        code: values.code,
        version: values.version,
      });
      reset();
      setSuccessMessage('Upload successful. Notification will be sent when procedure is generated.');
    } catch (error) {
      setGlobalErrors(new Error('Failed to generate procedure'));
    }
    setIsLoading(false);
    fileStagingManagerRef.current?.clearAttachments();
  };

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Procedure Generation</title>
      </Helmet>
      <FlashMessage message={successMessage} messageUpdater={setSuccessMessage} />
      <form className="flex flex-col pb-4" onSubmit={handleSubmit(onGenerate)}>
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="text-xl mb-1">Generate a new procedure</div>
            <div className="text-sm text-gray-900 mb-4">
              Upload a procedure, provide a code & version, and submit. You will receive a notification when the
              procedure is availabe as a draft.
            </div>
          </div>
          <div className="flex flex-row gap-x-1">
            <button
              className="rounded px-2 py-1 bg-blue-500 border border-solid border-blue-500 text-xs text-white disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-200"
              type="submit"
              disabled={isLoading}
            >
              Generate
            </button>
          </div>
        </div>
        {globalErrors && <p className="self-center mt-2 text-red-700">Error: {globalErrors.message}</p>}
        <div className="w-full p-4 bg-white rounded border border-solid border-gray-100 shadow">
          <div className="flex gap-x-12">
            <div className="flex-auto flex flex-col gap-y-4">
              <div className="flex flex-row gap-x-9">
                <div className="flex flex-col">
                  <label htmlFor="id" className="text-xs text-gray-500">
                    Unique ID*
                  </label>
                  <input
                    type="text"
                    className="border-1 border-gray-300 rounded"
                    placeholder="Procedure code"
                    {...register('code', {
                      required: 'Required',
                      maxLength: 128,
                      validate: (value) => {
                        return !!value.trim() || 'Unique ID must contain non whitespace characters.';
                      },
                    })}
                  />
                  <p className="text-red-700">{errors.code?.message}</p>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="id" className="text-xs text-gray-500">
                    Version*
                  </label>
                  <input
                    type="text"
                    className="border-1 border-gray-300 rounded"
                    placeholder="1.1"
                    {...register('version', {
                      required: 'Required',
                      maxLength: 128,
                      validate: (value) => {
                        return !!value.trim() || 'Version must contain non whitespace characters.';
                      },
                    })}
                  />
                  <p className="text-red-700">{errors.version?.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <FileStagingManager
        initialAttachments={[]}
        acceptedTypes={ACCEPTED_FILE_TYPES}
        maxFiles={1}
        ref={fileStagingManagerRef}
        source={SourceTypeEnum.ProceduresGeneration}
      />
      {isLoading && (
        <div className="w-1/3 text-sm font-medium uppercase animate-pulse bg-blue-500 text-white px-6 py-3 mt-3 rounded-full">
          Uploading...
        </div>
      )}
    </div>
  );
};

export default ProcedureGeneration;
