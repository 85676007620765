import React from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import userUtil from '../../lib/userUtil';
import DateTimeDisplay from '../DateTimeDisplay';
import EditCommentButton from '../EditCommentButton';
import TextLinkify from '../TextLinkify';
import { UserComment } from './types';
import { wasEdited } from 'shared/lib/comment';

interface CommentDisplayProps {
  activity: UserComment;
  onEdit: (comment) => void;
  canEdit?: boolean;
}

const CommentDisplay = ({ activity, onEdit, canEdit = true }: CommentDisplayProps) => {
  const { users } = useSettings();

  const getUserDisplayText = (userId: string): string => {
    const user = users && userUtil.getUserByEmailOrId(userId, users);
    return user?.email || userId;
  };

  const modifiedActivity = {
    ...activity,
    user_id: getUserDisplayText(activity.user_id),
  };

  return (
    <span className="text-sm">
      <div className="flex flex-col gap-y-2 px-3 bg-slate-200 rounded-lg">
        {/* comment top bar */}
        <div className="flex justify-between items-center flex-1">
          <div className="text-sm pt-1">
            <span className="font-semibold">{modifiedActivity.user_id} </span>
            <span>
              <DateTimeDisplay timestamp={activity.timestamp} />
            </span>
            {wasEdited(activity.timestamp, activity.updated_at) && <span className="ml-1 text-gray-400">(edited)</span>}
          </div>
          {canEdit && <EditCommentButton comment={modifiedActivity} onEdit={onEdit} />}
        </div>
        {/* comment body and footer */}
        <div className="pb-1">
          <TextLinkify mentionsEnabled={true} mentionList={activity.mentions}>
            <span className="whitespace-pre-line">{activity.comment}</span>
          </TextLinkify>
        </div>
      </div>
    </span>
  );
};

export default CommentDisplay;
