import { Field } from 'formik';
import { RangeError } from '../lib/types';

interface FieldSetRangeProps {
  path: string;
  isDisabled: boolean;
  rangeError?: RangeError;
  onChange: (value: string, path: string) => void;
}

// Renders fields for range min and max
const FieldSetRange = ({ path, isDisabled, rangeError, onChange }: FieldSetRangeProps) => {
  return (
    <>
      <div className="flex flex-col mr-2">
        <span className="field-title">Minimum</span>
        <Field name={`${path}.range.min`} validate={(value) => onChange(value, `${path}.range.min`)}>
          {({ field }) => (
            <input
              {...field}
              type="text"
              disabled={isDisabled}
              placeholder="Min"
              className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-300"
            />
          )}
        </Field>
        {rangeError?.min && <div className="text-red-700">{rangeError.min}</div>}
      </div>
      <div className="flex flex-col mr-2">
        <span className="field-title">Maximum</span>
        <Field name={`${path}.range.max`} validate={(value) => onChange(value, `${path}.range.max`)}>
          {({ field }) => (
            <input
              {...field}
              type="text"
              disabled={isDisabled}
              placeholder="Max"
              className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-300"
            />
          )}
        </Field>
        {rangeError?.max && <div className="text-red-700">{rangeError.max}</div>}
      </div>
    </>
  );
};

export default FieldSetRange;
