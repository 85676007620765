import { FormikHelpers, FormikValues, getIn } from 'formik';
import { NumberFieldSetContentErrors, OperationFieldName } from '../lib/types';
import { Rule } from 'shared/lib/types/views/procedures';
import FieldSetMathOperation from './FieldSetMathOperation';
import FieldSetRange from './FieldSetRange';
import FieldSetValue from './FieldSetValue';

interface FieldSetRuleProps {
  path: string;
  rule?: Rule;
  contentErrors: NumberFieldSetContentErrors;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  operationFieldName: OperationFieldName;
  onChangeValueField: (value: string, path: string) => void;
  isDisabled?: boolean;
  disabledFields?: unknown;
}

const FieldSetRule = ({
  path,
  rule,
  contentErrors,
  setFieldValue,
  operationFieldName,
  onChangeValueField,
  isDisabled,
  disabledFields = {},
}: FieldSetRuleProps) => {
  const showsRangeFields = rule?.op === 'range';
  const showsValueField = rule?.op !== 'range';
  return (
    <div className="flex flex-row">
      {/* Parameter rule */}
      <FieldSetMathOperation
        path={path}
        rule={rule}
        operationFieldName={operationFieldName}
        setFieldValue={setFieldValue}
        operatorError={contentErrors?.rule?.op}
        isDisabled={isDisabled || getIn(disabledFields, 'rule_operation')}
        hasDefaultValue={true}
      />

      {/* Parameter range min and max values */}
      {showsRangeFields && (
        <FieldSetRange
          path={path}
          rangeError={contentErrors?.rule?.range}
          onChange={onChangeValueField}
          isDisabled={isDisabled || getIn(disabledFields, 'rule_range')}
        />
      )}

      {/* Parameter value */}
      {showsValueField && (
        <FieldSetValue
          path={path}
          dataType={null}
          valueError={contentErrors?.rule?.value}
          onChange={onChangeValueField}
          isDisabled={isDisabled || getIn(disabledFields, 'rule_value')}
        />
      )}
    </div>
  );
};

export default FieldSetRule;
