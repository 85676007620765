export const isEdgeInViewport = (
  edge: number,
  viewportHeight: number
): boolean => {
  return 0 <= edge && edge <= viewportHeight;
};

export const isItemInViewport = (
  top: number,
  bottom: number,
  viewportHeight: number
): boolean => {
  return (
    isEdgeInViewport(top, viewportHeight) ||
    isEdgeInViewport(bottom, viewportHeight) ||
    (top <= 0 && bottom >= viewportHeight)
  );
};

const virtualizationUtil = {
  isElementInViewport: (element: HTMLElement | null): boolean => {
    if (!element) {
      return false;
    }

    const { top, bottom } = element.getBoundingClientRect();
    const { innerHeight } = window;

    /**
     * Returns true if either or both the top and bottom edges are in the viewport,
     * or if the top edge is above the viewport and the bottom edge is below the viewport (element larger than the viewport);
     */
    return isItemInViewport(top, bottom, innerHeight);
  },
};

export default virtualizationUtil;
