import React from 'react';
import { Link } from 'react-router-dom';

import StepNameSpan from './StepNameSpan';
import SectionStepKeyWithStatus from './SectionStepKeyWithStatus';

import { Section, Step } from 'shared/lib/types/views/procedures';
import BatchProcedureStepBadge from '../BatchSteps/BatchProcedureStepBadge';

interface StepSpanProps {
  linkTo: string;
  sectionStepKey: string;
  currentSection: Section;
  currentStep: Step;
  currentStepIndex: number;
  highlight: boolean;
  isBatchStep: boolean;
}

const StepSpan = React.memo(
  ({
    linkTo,
    sectionStepKey,
    currentSection,
    currentStep,
    currentStepIndex,
    highlight,
    isBatchStep,
  }: StepSpanProps) => {
    return (
      <Link to={linkTo}>
        <div className="relative flex flex-row w-full truncate text-sm cursor-pointer hover:text-blue-600">
          <div className="absolute h-full w-20 left-toc-line border-l border-gray-500 opacity-50" />
          {/* Gray background behind current step. Separate div that can
                z-index behind the TOC vertical line while the StepSpan div
                z-indexes on top of the line. */}
          <div className={`absolute w-full h-full rounded -z-30 ${highlight ? 'bg-gray-200' : ''}`} />
          <div className="flex flex-row flex-nowrap items-center w-full px-2 py-2">
            <SectionStepKeyWithStatus sectionStepKey={sectionStepKey} currentStep={currentStep} />
            <StepNameSpan
              currentSection={currentSection}
              currentStep={currentStep}
              currentStepIndex={currentStepIndex}
            />
            {isBatchStep && <BatchProcedureStepBadge />}
          </div>
        </div>
      </Link>
    );
  }
);

export default StepSpan;
