import React, { useMemo } from 'react';
import Select, { Option, StylesConfig } from 'react-select';
import { reactSelectNoBorderStyles, reactSelectStyles } from '../lib/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProcedureDiffCompareText from '../components/ProcedureDiffCompareText';

/**
 * Do not use react-select to render a disabled selector, in order to improve performance
 */
interface SelectorProps {
  name: string;
  type: 'table-cell' | 'standalone';
  description: string;
  isDisabled: boolean;
  selectedOption: Option;
  selectOptions: Array<Option>;
  onChangeHandler: (option: Option) => void;
  styles?: StylesConfig;
  isDiff?: boolean;
  showOptionsWhenDisabled?: boolean;
}

const Selector = ({
  name,
  type = 'standalone',
  description,
  isDisabled,
  selectedOption,
  selectOptions,
  onChangeHandler,
  isDiff = false,
  showOptionsWhenDisabled = false,
}: SelectorProps) => {
  const placeholder = `Select ${description}`;

  const borderAndSizeClass = useMemo(() => {
    if (type === 'table-cell') {
      return 'w-full h-full border-none';
    }

    return 'border rounded';
  }, [type]);

  const disabledClass = useMemo(() => {
    if (type === 'table-cell') {
      return '';
    }

    return 'bg-gray-200 border-gray-400';
  }, [type]);

  const styles = useMemo(() => (type === 'table-cell' ? reactSelectNoBorderStyles : reactSelectStyles), [type]);

  return (
    <>
      {isDisabled && (
        <div
          className={`relative px-2.5 py-1 min-h-[37px] flex items-center ${borderAndSizeClass} ${disabledClass}`}
          aria-label={`${description} Select`}
        >
          <div
            className={`mr-3 text-sm flex flex-grow justify-between items-center ${
              isDiff ? 'line-clamp-2' : 'line-clamp-1'
            }`}
          >
            {!isDiff && !showOptionsWhenDisabled && (
              <span className="truncate">{selectedOption?.label ?? placeholder}</span>
            )}
            {!isDiff && showOptionsWhenDisabled && (
              <div className="flex flex-wrap gap-1 w-full">
                {selectOptions.map((option) => (
                  <span className="p-1 bg-gray-200 rounded truncate max-w-64">{option.label}</span>
                ))}
              </div>
            )}
            {isDiff && <ProcedureDiffCompareText diffValue={selectedOption.label} />}
          </div>
          <FontAwesomeIcon icon="chevron-down" className="right-0 mr-0.5 text-gray-500" />
        </div>
      )}
      {!isDisabled && (
        <Select
          aria-label={`${description} Select`}
          components={{
            ...(type === 'table-cell' && { IndicatorSeparator: () => null }),
          }}
          classNamePrefix="react-select"
          className={borderAndSizeClass}
          name={name}
          isDisabled={isDisabled}
          onChange={onChangeHandler}
          options={selectOptions}
          placeholder={placeholder}
          styles={styles}
          value={selectedOption}
        />
      )}
    </>
  );
};

export default Selector;
