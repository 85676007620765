import { API_URL } from '../config';
import superlogin from './superlogin';
import {
  GenerateProcedureQuery,
  GenerateProcedureResponse,
  GenerateProcedureWithAttachmentRequest,
  GenerateProcedureWithAttachmentResponse,
} from '../ml/types';
import { SourceTypeEnum } from 'shared/lib/types/attachments';

class MlService {
  private teamId: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/ml`;
  }

  async generateProcedure(
    generateProcedureQuery: GenerateProcedureQuery
  ): Promise<GenerateProcedureResponse> {
    const url = `${this.restUrl}/procedure-generation`;
    const body = {
      query: generateProcedureQuery.query,
      code: generateProcedureQuery.code,
      version: generateProcedureQuery.version,
      generation_version: generateProcedureQuery.generation_version,
    };
    const response = await superlogin.getHttp().post(url, body);
    return response.data;
  }

  async processProcedureAttachment(
    generateProcedureWithAttachment: GenerateProcedureWithAttachmentRequest
  ): Promise<GenerateProcedureWithAttachmentResponse> {
    const url = `${this.restUrl}/procedure-generation/process-attachment`;
    const { code, version, file } = generateProcedureWithAttachment;

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('source', SourceTypeEnum.ProceduresGeneration);
    formData.append('file', file);
    formData.append('code', code);
    formData.append('version', version);

    const response = await superlogin.getHttp().post(url, formData);
    return response.data;
  }
}

export default MlService;
