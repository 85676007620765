import { useMemo, useCallback } from 'react';
import fieldInputUtil from '../lib/fieldInputUtil';
import { FieldInputBlock, RunFieldInputRecordedValue } from 'shared/lib/types/views/procedures';
import { isEmptyValue } from 'shared/lib/text';
import ProcedureFieldDiff from '../components/ProcedureFieldDiff';

type UseDisplayRuleProps = {
  block: FieldInputBlock;
  recorded?: { value?: RunFieldInputRecordedValue };
  redlinedBlock?: FieldInputBlock;
};

const useDisplayRule = ({ block, recorded, redlinedBlock }: UseDisplayRuleProps) => {
  const displayPass = useMemo(() => {
    return fieldInputUtil.isNumberFieldInputPassing(block, recorded);
  }, [block, recorded]);

  const getDisplayRule = useCallback(
    (passBlock = undefined) => {
      let currentBlock = block;

      if (passBlock) {
        currentBlock = passBlock;
      }

      if (currentBlock.inputType !== 'number') return;

      const rule = currentBlock.rule;
      const range = rule?.range ?? null;
      if (!rule || isEmptyValue(rule) || isEmptyValue(rule.op)) {
        return null;
      }
      // Ranges define a `min` and `max` instead of a `value`.
      if (rule.op === 'range') {
        if (isEmptyValue(range) || isEmptyValue(range?.min) || isEmptyValue(range?.max)) {
          return null;
        }
        return (
          <span>
            {redlinedBlock && redlinedBlock.inputType === 'number' && (
              <ProcedureFieldDiff
                original={currentBlock.rule?.range?.min?.toString() ?? ''}
                redlined={redlinedBlock.rule?.range?.min?.toString() ?? ''}
              />
            )}
            {!redlinedBlock && range?.min}
            <span> &lt; Value &lt; </span>
            {redlinedBlock && redlinedBlock.inputType === 'number' && (
              <ProcedureFieldDiff
                original={currentBlock.rule?.range?.max?.toString() ?? ''}
                redlined={redlinedBlock.rule?.range?.max?.toString() ?? ''}
              />
            )}
            {!redlinedBlock && range?.max}
          </span>
        );
      }
      // Standard block rules define a single `value`
      if (isEmptyValue(rule.value)) {
        return null;
      }
      const op = rule.op;

      return (
        <>
          {op}
          {/* Ensure that there's a space between the operator and the value. */}
          <span> </span>
          {redlinedBlock && redlinedBlock.inputType === 'number' && (
            <ProcedureFieldDiff
              original={currentBlock.rule?.value?.toString() ?? ''}
              redlined={redlinedBlock.rule?.value?.toString() ?? ''}
            />
          )}
          {!redlinedBlock && rule.value}
        </>
      );
    },
    [block, redlinedBlock]
  );

  return { displayPass, getDisplayRule };
};

export default useDisplayRule;
