import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActivityType } from './types';

export interface TimelineIconProps {
  activityType: ActivityType;
}

const TimelineIcon = ({ activityType }: TimelineIconProps) => {
  let color = 'text-blue-200';
  let icon = 'pencil';

  switch (activityType) {
    case 'comment':
      icon = 'comment';
      color = 'text-gray-200';
      break;
    default:
      icon = 'file';
      break;
  }

  return (
    <div className="flex-none w-6 h-full">
      {/* Overlay a background-colored circle underneath the actual status icon circle so that the blue line does not show through */}
      <div className="absolute ml-3 h-full border-l border-gray-500 opacity-50" />
      <FontAwesomeIcon icon="circle" className="absolute text-3xl text-white" />
      <FontAwesomeIcon icon="circle" className={`z-5 absolute mt-0.5 mr-1 text-2xl ${color}`} />
      <FontAwesomeIcon icon={icon as IconName} className="fa-fw ml-1 z-6 mt-2 absolute text-gray-600 text-xs" />
    </div>
  );
};

export default TimelineIcon;
