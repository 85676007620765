import React, { useCallback, useRef } from 'react';
import CommentDisplay from './ActivityDisplay/CommentDisplay';
import TimelineIcon from './ActivityDisplay/TimelineIcon';
import { Activity, UserComment } from './ActivityDisplay/types';
import FormStepComment from './FormStepComment';
import { Mention } from 'shared/lib/types/postgres/util';
import { isNil } from 'lodash';
import useCommentHandler from '../hooks/useCommentHandler';
import { CommentInterface } from 'shared/lib/comment';

type ActivityDisplayProps = {
  activities: Activity[];
  activityAscending: boolean;
  onAddComment?: (comment: string, mentions: Mention[]) => Promise<void>;
  onEditComment?: (comment: CommentInterface, commentId?: string) => Promise<void>;
  enableScrolling?: boolean;
  autoFocus?: boolean;
};

const ActivityDisplay = ({
  activities,
  activityAscending,
  onAddComment,
  onEditComment,
  enableScrolling = false,
  autoFocus = false,
}: ActivityDisplayProps) => {
  const bottomDivRef = useRef<HTMLDivElement>(null);
  const { isSubmittingComment, hasCommentError, editCommentContext, submitComment, handleEditClick, handleCancel } =
    useCommentHandler({ onAddComment, onEditComment, autoFocus });

  const SCROLL_THRESHOLD = 5;
  const activityContainerClass = `flex ${
    activityAscending ? 'flex-col' : 'flex-col-reverse'
  } grow items-start w-full rounded bg-white p-4 gap-y-2 ${
    enableScrolling && activities.length >= SCROLL_THRESHOLD ? 'overflow-y-scroll max-h-96' : ''
  }`;

  const handleSubmit = useCallback(
    async (values: CommentInterface, helpers: { resetForm: () => void }) => {
      await submitComment(values, helpers);
      bottomDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [submitComment]
  );

  return (
    <div className="flex grow w-full pb-4">
      <div role="region" aria-label="Activity List" className="w-full">
        {activities.length > 0 && (
          <div className={activityContainerClass}>
            {activities.map((activity, index) => (
              <div className="w-full relative h-auto" key={index}>
                <div
                  key={`activity-${index}`}
                  role="row"
                  aria-rowindex={index}
                  className="flex flex-row text-sm my-2 gap-x-2"
                >
                  <TimelineIcon activityType={activity.type} />
                  <span className="w-full inline-block mt-1 whitespace-normal">
                    {activity.type === 'comment' && (
                      <>
                        {editCommentContext.id === activity.id ? (
                          <FormStepComment
                            autoFocus={autoFocus}
                            placeholder="Edit comment"
                            initialValues={{ comment: editCommentContext.text, mentions: editCommentContext.mentions }}
                            onSubmit={submitComment}
                            mentionEnabled={true}
                            onCancel={handleCancel}
                          />
                        ) : (
                          <CommentDisplay
                            activity={activity as UserComment}
                            onEdit={handleEditClick}
                            canEdit={Boolean(onEditComment)}
                          />
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
            ))}
            <div ref={bottomDivRef} />
          </div>
        )}
        {activities.length === 0 && isNil(onAddComment) && <div className="text-gray-400 text-sm">No comments</div>}
        <div className="w-full">
          {!isSubmittingComment && !isNil(onAddComment) && (
            <FormStepComment
              autoFocus={autoFocus}
              placeholder="Add comment"
              onSubmit={handleSubmit}
              mentionEnabled={true}
              disabled={!isNil(editCommentContext?.id)}
            />
          )}
          {isSubmittingComment && <div>Saving Comment...</div>}
          {hasCommentError && (
            <div className="flex items-center text-red-700">Error saving comment. Please try again.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityDisplay;
