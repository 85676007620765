import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useNavState } from '../contexts/NavContext';
import LastSavedDisplay from './Time/LastSavedDisplay';
import Button, { BUTTON_TYPES } from './Button';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import Label from './Label';
import NavigationRedlines from './NavigationRedlines';
import { ScrollEntry } from '../hooks/useScroll';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import { useSelectionContext } from '../contexts/Selection';

export const EDIT_STICKY_HEADER_HEIGHT_REM = 1;

export interface EditToolbarProps {
  procedureTitle: string;
  procedureState?: ProcedureState;
  showSubmitError: boolean;
  onGoToError: () => void;
  submissionErrors: string | null;
  isDirty: boolean;
  isSubmitting: boolean;
  lastSavedTime: Date | null;
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
  hasPast: boolean;
  hasFuture: boolean;
  onUndo: () => void;
  onRedo: () => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onPreview: () => void;
  isPreviewDisabled: boolean;
  onSave: () => void;
  isSaveDisabled: boolean;
  onValidateReview: (settings?: { reviewTypeId?: string }) => void;
  isReviewDisabled: boolean;
  goToRedline: (entry: ScrollEntry) => void;
  redlineFieldList: Array<ScrollEntry>;
  unresolvedActionsCount: number;
}

const EditToolbar = ({
  procedureTitle,
  procedureState,
  showSubmitError,
  onGoToError,
  submissionErrors,
  isDirty,
  isSubmitting,
  lastSavedTime,
  currentTab,
  setCurrentTab,
  hasPast,
  hasFuture,
  onUndo,
  onRedo,
  onExpandAll,
  onCollapseAll,
  onPreview,
  isPreviewDisabled,
  onSave,
  isSaveDisabled,
  onValidateReview,
  isReviewDisabled,
  goToRedline,
  redlineFieldList,
  unresolvedActionsCount,
}: EditToolbarProps) => {
  const navState = useNavState();

  const isDraft = useMemo(() => {
    if (!procedureState) {
      return false;
    }
    return procedureState === 'draft';
  }, [procedureState]);

  const chipLabel = useMemo(() => (currentTab === 'chart' ? 'Flow View' : 'Draft'), [currentTab]);

  const onReviewButtonClicked = () => {
    onValidateReview();
  };

  const { clearSelections } = useSelectionContext();

  const handleComponentClick = (e) => {
    if (!e.target.closest('.selectable-block')) {
      clearSelections();
    }
  };

  return (
    <div
      onClick={handleComponentClick}
      className={`fixed top-0 ${
        navState.collapsed ? 'left-16' : 'left-64'
      } transition-all right-0 pl-2 pr-4 h-10 flex flex-row justify-between shadow-md bg-gray-100 z-50 print:hidden`}
    >
      <div className="h-full flex items-center whitespace-nowrap text-ellipsis overflow-hidden w-1/2">
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, currentTab === 'list')}
          type="button"
          title="Edit Draft"
          disabled={currentTab === 'list'}
          onClick={() => setCurrentTab('list')}
        >
          <FontAwesomeIcon icon="pencil-alt" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(isPreviewDisabled, false)}
          type="button"
          title="Preview Procedure"
          aria-label="Preview Procedure"
          disabled={isPreviewDisabled}
          onClick={onPreview}
        >
          <FontAwesomeIcon icon={'fa-solid fa-eye' as IconProp} />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, currentTab === 'chart')}
          type="button"
          title="Flow View"
          disabled={currentTab === 'chart'}
          onClick={() => setCurrentTab('chart')}
        >
          <FontAwesomeIcon icon="sitemap" />
        </button>
        <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 ml-1 mr-2" />
        {isDraft && <Label text={chipLabel} color="bg-gray-300" enableTruncate={false} />}
        <span className="ml-2 truncate">{procedureTitle}</span>
      </div>
      {/* Global form errors */}
      <div className="h-full flex justify-center grow">
        {showSubmitError && (
          <div className="flex justify-center m-1">
            <span className="mx-1 text-red-700 self-center">Missing or Invalid Fields</span>
            <span className="link self-center cursor-pointer" onClick={onGoToError}>
              Go To Error
            </span>
          </div>
        )}
        {/* Show user any submission errors */}
        {submissionErrors && <div className="flex items-center text-red-700">{submissionErrors}</div>}
      </div>
      {!(showSubmitError || submissionErrors) && (
        <div className="pb-1 pr-2 self-center whitespace-nowrap hidden lg:block">
          <LastSavedDisplay lastSavedTime={lastSavedTime} isSubmitting={isSubmitting} isDirty={isDirty} />
        </div>
      )}
      <div className="h-full bg-gray-100 items-center flex flex-row justify-end">
        {redlineFieldList.length > 0 && (
          <>
            <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 mx-1" />
            <NavigationRedlines
              redlineFieldList={redlineFieldList}
              goToRedline={goToRedline}
              unresolvedActionsCount={unresolvedActionsCount}
            />
          </>
        )}
        <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 mx-1" />
        <button
          className={stickyHeaderUtil.getButtonClassNames(!hasPast, false)}
          type="button"
          title="Undo"
          disabled={!hasPast}
          onClick={onUndo}
        >
          <FontAwesomeIcon icon="undo" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(!hasFuture, false)}
          type="button"
          title="Redo"
          disabled={!hasFuture}
          onClick={onRedo}
        >
          <FontAwesomeIcon icon="redo" />
        </button>
        <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 mx-1" />
        <ExpandCollapseButtons
          buttonClasses={stickyHeaderUtil.getButtonClassNames(currentTab === 'chart', false)}
          onExpand={onExpandAll}
          onCollapse={onCollapseAll}
          isDisabled={currentTab === 'chart'}
        />
        <div className="bg-slate-300 min-w-[1px] w-[1px] h-8 mx-1" />
        <button
          className={stickyHeaderUtil.getButtonClassNames(isSaveDisabled, false)}
          type="button"
          title="Save Procedure"
          aria-label="Save Procedure"
          disabled={isSaveDisabled}
          onClick={onSave}
        >
          <FontAwesomeIcon icon="save" />
        </button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          ariaLabel="Review"
          isDisabled={isReviewDisabled}
          onClick={onReviewButtonClicked}
          size="sm"
        >
          Review
        </Button>
      </div>
    </div>
  );
};

export default EditToolbar;
