import React, { useMemo } from 'react';
import runUtil from '../../lib/runUtil';
import { useSettings } from '../../contexts/SettingsContext';
import SectionSpan from './SectionSpan';
import StepSpan from './StepSpan';
import { INTRO_STEP_KEY, useRunContext } from '../../contexts/RunContext';
import { VirtualScroller } from 'primereact/virtualscroller';
import { BatchStepProps, Draft, Run, RunStep, Section, Step } from 'shared/lib/types/views/procedures';
import tocUtil from './TOCutil';
import { BlockType, getScrollToUrlParams } from '../../lib/scrollToUtil';
import { groupBatchStepsInRun } from '../../lib/batchSteps';

interface TOCItem {
  index: number;
}
interface StepItem extends TOCItem {
  type: 'step';
  section: Section;
  sectionIndex: number;
  item: Step;
  isBatchStep: boolean;
}

interface SectionItem extends TOCItem {
  type: 'section';
  item: Section;
}

const TOC_ROW_HEIGHT = 38;

export interface TOCSidebarProps {
  displaySections: Array<[Section, number]>;
  run: Run | Draft;
  height: number;
  stickyMargin?: number;
  onBeforeNavigate?: () => void;
}

const getIdAndTypeForStepLink = (stepItem: StepItem): { id: string; type: BlockType } => {
  if (stepItem.isBatchStep) {
    const step = stepItem.item as RunStep;
    const batchProps = step.batchProps as BatchStepProps;
    return { id: batchProps.batchId, type: 'batchStep' };
  }
  return { id: stepItem.item.id, type: 'step' };
};

const TOCSidebar = ({ displaySections, run, height, onBeforeNavigate }: TOCSidebarProps) => {
  const { getSetting } = useSettings();
  const { isSingleCardEnabled, isStepVisible, isIntroductionVisible } = useRunContext();

  const tocItems = useMemo(() => {
    const list: Array<SectionItem | StepItem> = [];
    for (const [section, sectionIndex] of displaySections) {
      list.push({ type: 'section', index: sectionIndex, item: section });

      const steps = groupBatchStepsInRun(section.steps ?? []);
      for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
        const stepOrBatch = steps[stepIndex];
        const isBatchStep = Array.isArray(stepOrBatch);
        const step = Array.isArray(stepOrBatch) ? stepOrBatch[stepOrBatch.length - 1] : stepOrBatch;
        list.push({ type: 'step', index: stepIndex, sectionIndex, item: step, section, isBatchStep });
      }
    }
    return list;
  }, [displaySections]);

  const isUnvirtualized = useMemo(() => {
    return tocItems.length < tocUtil.getThresholdItemCountForVirtualization();
  }, [tocItems.length]);

  const keyDisplayStyle = useMemo(() => {
    return getSetting('display_sections_as', 'letters');
  }, [getSetting]);

  const contentTemplate = () => {
    return (
      <div className="overflow-y-auto" style={{ height: `${height}px` }}>
        {tocItems.map((item) => itemTemplate(item))}
      </div>
    );
  };

  const itemTemplate = (item: SectionItem | StepItem) => {
    if (item.type === 'section') {
      const section = item.item as Section;
      return (
        <SectionSpan
          key={section.id}
          linkTo={getScrollToUrlParams({ id: section.id, type: 'section' })}
          sectionKey={runUtil.displaySectionKey(run.sections, item.index, keyDisplayStyle)}
          sectionRepeatKey={runUtil.displayRepeatKey(run.sections, item.index)}
          currentSection={section}
        />
      );
    } else {
      const step = item.item as Step;
      return (
        <StepSpan
          key={step.id}
          linkTo={getScrollToUrlParams(getIdAndTypeForStepLink(item))}
          sectionStepKey={runUtil.displaySectionStepKey(run.sections, item.sectionIndex, item.index, keyDisplayStyle)}
          currentSection={item.section}
          currentStep={step}
          currentStepIndex={item.index}
          highlight={isSingleCardEnabled && isStepVisible(step)}
          isBatchStep={item.isBatchStep}
        />
      );
    }
  };

  return (
    <div
      onMouseDown={onBeforeNavigate}
      aria-label="toc-sidebar"
      className="flex flex-col justify-content-start space-y-2 border-0"
    >
      {isSingleCardEnabled && (
        <div className="mt-1 border-t-2">
          <div className={`py-1 mt-2 rounded ${isIntroductionVisible() ? 'bg-gray-200' : ''}`}>
            <SectionSpan
              linkTo={getScrollToUrlParams({ id: INTRO_STEP_KEY, type: 'intro' })}
              sectionKey=""
              currentSection={{ name: 'Introduction' }}
            />
          </div>
        </div>
      )}
      <VirtualScroller
        contentTemplate={isUnvirtualized && contentTemplate}
        disabled={isUnvirtualized}
        items={tocItems}
        itemSize={TOC_ROW_HEIGHT}
        itemTemplate={itemTemplate}
        scrollHeight={`${height}px`}
      />
    </div>
  );
};

export default React.memo(TOCSidebar);
