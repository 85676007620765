import cloneDeep from 'lodash.clonedeep';
import SubstepNumber from '../../components/SubstepNumber';
import { generateHiddenClassString } from '../../lib/styles';
import { Checkbox, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import { TestCase } from '../types';
import renderDateTime from '../../components/Home/Renderers/DateTime';
import { capitalizeFirstLetter } from 'shared/lib/text';
import usePopup from '../../hooks/usePopup';
import AssessmentIndicator from './AssessmentIndicator';
import React, { useMemo } from 'react';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import HazardPopup from './HazardPopup';

export type TestCaseBlock = {
  id: string;
  items: Array<TestCase>;
  type: string;
};

interface TestCasesProps {
  content: TestCaseBlock;
  recorded: TestCaseBlock;
  isHidden: boolean;
  isEnabled: boolean;
  blockLabel: string;
  onRecordValuesChanged: (id: string, updated: TestCaseBlock) => void;
  isStepComplete: boolean;
}

const TestCasesBlock = ({
  content,
  recorded,
  isHidden,
  isEnabled,
  blockLabel,
  onRecordValuesChanged,
  isStepComplete,
}: TestCasesProps) => {
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();

  const data = useMemo(() => {
    if (recorded) {
      return recorded;
    }
    return content;
  }, [content, recorded]);

  const onTestCaseChanged = (item, changeType) => {
    if (!isEnabled) {
      return;
    }
    const updated = recorded ? cloneDeep(recorded) : cloneDeep(content);
    const itemIndex = updated.items.findIndex((updatedItem) => item.id === updatedItem.id);
    if (itemIndex !== -1) {
      const currentItem = updated.items[itemIndex];
      if (changeType === 'startedAt') {
        updated.items[itemIndex] = {
          ...currentItem,
          startedAt: currentItem.startedAt ? null : DateTime.utc().toString(),
        };
      } else if (changeType === 'completed') {
        updated.items[itemIndex] = {
          ...currentItem,
          completed: currentItem.completed ? null : DateTime.utc().toString(),
        };
      }
      onRecordValuesChanged?.(content.id, updated);
    }
  };

  const columnHeaders: string[] = [
    ...content.items.reduce((acc, testCase) => {
      testCase.test_case_conditions.forEach((condition) => acc.add(condition.name));
      return acc;
    }, new Set<string>()),
  ];

  const handleQualityChange = (event: SelectChangeEvent, testCaseId: string) => {
    if (!isEnabled) {
      return;
    }
    const updated = recorded ? cloneDeep(recorded) : cloneDeep(content);
    const itemIndex = updated.items.findIndex((item) => item.id === testCaseId);
    if (itemIndex !== -1) {
      updated.items[itemIndex].quality = event.target.value as unknown as string;
      onRecordValuesChanged?.(content.id, updated);
    }
  };

  return (
    <>
      <div className={generateHiddenClassString('', isHidden)} />
      <div className="overflow-x-auto max-w-full">
        <div className={generateHiddenClassString('mt-3 ml-4 mr-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <table className="w-full border-collapse">
            <thead>
              <tr className="items-center">
                {/* Adjust each header cell with truncate and a set max-width */}
                <td className="border border-gray-400 p-1 max-w-xs">
                  <div className="truncate">
                    <span>Test Point</span>
                  </div>
                </td>
                {columnHeaders.map((header, index) => (
                  <td key={index} className="border border-gray-400 p-1 max-w-xs">
                    <div className="truncate">
                      <span className="field-title">{capitalizeFirstLetter(header)}</span>
                    </div>
                  </td>
                ))}
                <td className="border border-gray-400 p-1 max-w-xs">
                  <div className="truncate">
                    <span className="field-title">Hazard</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Started</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Completed</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Quality</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {data?.items.map((testCase) => (
                <tr key={testCase.id} className="h-10 text-sm border items-center">
                  <td className="border border-gray-400 p-1 max-w-xs">
                    <div className="break-words">
                      <label className="text-sm truncate">{testCase.name}</label>
                    </div>
                  </td>
                  {columnHeaders.map((header) => {
                    const condition = testCase.test_case_conditions.find((cond) => cond.name === header);
                    return (
                      <td key={header} className="border border-gray-400 p-1 max-w-xs">
                        <div className="truncate break-words">
                          {condition ? (
                            <div className="flex flex-row gap-x-1">
                              {`${condition.value} `}
                              <UnitDisplay unit={condition.units} />
                            </div>
                          ) : null}
                        </div>
                      </td>
                    );
                  })}
                  {/* Display Test Point Hazard with truncate */}
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {testCase?.hazards &&
                      testCase?.hazards.map((hazard) => {
                        return (
                          <div key={hazard.id} className="flex space-x-2 items-center truncate break-words">
                            <AssessmentIndicator hazard={hazard} />
                            <span
                              className="text-sm text-blue-600 hover:underline truncate cursor-pointer"
                              onClick={(e) => handlePopupOpen(e, hazard.id)}
                            >
                              {hazard.name}
                            </span>
                            <HazardPopup
                              isPopUpShown={activeChildId === hazard.id}
                              onPopupClose={handlePopupClose}
                              hazard={hazard}
                            />
                          </div>
                        );
                      })}
                  </td>
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {/* Start Time */}
                    <div className="flex items-center">
                      {testCase.startedAt ? (
                        <div className="flex-auto flex flex-col gap-y-1 ml-1 leading-4">
                          <div className="text-sm text-gray-900">{renderDateTime(testCase.startedAt)}</div>
                        </div>
                      ) : (
                        <div className="ml-1">
                          <Checkbox
                            onClick={() => onTestCaseChanged?.(testCase, 'startedAt')}
                            disabled={!isEnabled || Boolean(testCase.startedAt)}
                            size="small"
                          ></Checkbox>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {/* End Time */}
                    <div className="flex items-center">
                      {testCase.completed && isStepComplete ? (
                        <div className="flex-auto flex flex-col gap-y-1 ml-1 leading-4">
                          <div className="text-sm text-gray-900">{renderDateTime(testCase.completed)}</div>
                        </div>
                      ) : (
                        <div className="ml-1">
                          <Checkbox
                            onClick={() => onTestCaseChanged?.(testCase, 'completed')}
                            disabled={!isEnabled || !testCase.startedAt}
                            size="small"
                            checked={Boolean(testCase.completed)}
                          ></Checkbox>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="border border-gray-400 max-w-xs">
                    <div className="flex justify-center">
                      {isStepComplete ? (
                        <div className="flex-auto flex flex-col gap-y-1 ml-1 leading-4">
                          <div className="text-sm text-gray-900">{testCase.quality}</div>
                        </div>
                      ) : (
                        <Select
                          value={testCase.quality || ''}
                          label="Quality"
                          size="small"
                          onChange={(event) => handleQualityChange(event, testCase.id)}
                          disabled={!isEnabled}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                        </Select>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TestCasesBlock;
