import React, { useMemo } from 'react';
import { getAssessmentColor, getHazardColumnName, getHazardRowName, AssessmentColor } from '../libs/hazards';
import Tooltip from '../../elements/Tooltip';
import { Hazard } from 'shared/lib/types/testing';

interface AssessmentIndicatorProps {
  hazard: Hazard;
}

const AssessmentIndicator = ({ hazard }: AssessmentIndicatorProps) => {
  const displayString = `${getHazardRowName(hazard)} and ${getHazardColumnName(hazard)}`;
  const { backgroundColor, backgroundColorName }: AssessmentColor = useMemo(() => {
    return getAssessmentColor(hazard);
  }, [hazard]);

  return (
    <Tooltip content={displayString}>
      <div
        aria-label={`${displayString}${backgroundColorName ? `:${backgroundColorName}` : ''}`}
        className="flex items-center justify-center h-3 w-3 shrink-0 rounded-full"
        style={{ backgroundColor }}
      />
    </Tooltip>
  );
};

export default AssessmentIndicator;
