import { RunTag, Tag } from './settings';

/*
 * =============================================================================
 * Metadata types
 * =============================================================================
 */
export type ProcedureMetadata = {
  _id: string;
  _rev: string;
  name: string;
  procedure_id?: string;
  state: ProcedureState;
  code: string;
  version: string;
  project_id: string;
  archived?: boolean;
  editedAt: string;
  reviewer_groups?: Array<ReviewerGroup>;
  tags?: Array<Tag>;
  procedure_type?: ProcedureType;
  automation_status?: AutomationStatus;
  start_run_signoffs_groups?: Array<StartRunSignoffsGroup>;
  procedure_rev_num?: string;
  automation_enabled?: boolean;
};

export type RunMetadata = {
  source_run: string;
  _id: string;
  code: string;
  completedAt?: string;
  name: string;
  run_number?: number;
  operation?: string;
  project_id?: string;
  tags?: Array<Tag>;
  run_tags?: Array<RunTag>;
  run_section?: string;
  starttime: string;
  state?: RunState;
  status?: RunStatus;
  skipped_steps: number;
  completed_steps: number;
  not_required_steps: number;
  failed_steps: number;
  total_steps: number;
  participant_user_ids: Array<string>;
  procedure_type?: ProcedureType;
  automation_status?: AutomationStatus;
  automation_enabled?: boolean;
};

export type RedlinesMetadata = {
  id: string;
  key: string;
  value: number;
};

/*
 * =============================================================================
 * Procedure types
 * =============================================================================
 */
export type Reviewer = {
  id: string;
  reviewer_ids: Array<ReviewerId>;
  is_required?: true;
};
export type StartRunSignoffsGroup = {
  id: string;
  operators: Array<string>;
};
export type ReviewerGroup = {
  id: string;
  reviewers: Array<Reviewer>;
  actions: Array<ReviewerAction>;
  name?: string;
  persist_approvals?: boolean;
};
export type ReviewType = {
  id: string;
  name: string;
  reviewer_groups: Array<ReviewerGroup>;
};
export type EndRunSignoffsGroup = {
  id: string;
  operators: Array<string>;
};

export type ApproveAction = {
  type: 'approval';
  reviewer_id: string;
  user_id: string;
  approved_at: string;
  operator_role?: string;
};

export type RevokeApprovalAction = {
  type: 'revoke_approval';
  reviewer_id: string;
  user_id: string;
  changed_at: string;
};

export type ProcedureChangeAction = {
  type: 'procedure_change';
  user_id: string;
  changed_at: string;
  procedure_rev_num: number | null;
};

export type ReviewerGroupChangeAction = {
  type: 'review_type_change' | 'reviewer_group_change'; // 'reviewer_group_change' is deprecated
  user_id: string;
  changed_at: string;
  changed_from: string | null;
  changed_to: string | null;
};

export type ReviewerAction = ApproveAction | RevokeApprovalAction;

export type DraftAction = ProcedureChangeAction | ReviewerGroupChangeAction;

export type ReviewerUserId = {
  type: 'user_id';
  value: string;
};

export type ReviewerOperatorRoleId = {
  type: 'operator_role';
  value: string;
  user_ids?: Array<string>;
};

export type ReviewerId = ReviewerUserId | ReviewerOperatorRoleId;

export type ProcedureState = DraftState | ReleaseState | RunState;

export type DraftState = 'draft' | 'in_review';

export type ReleaseState = 'released';

export type RunState = 'completed' | 'running' | 'paused';
export type RunStatus = 'success' | 'abort' | 'failure';

export type StepState = 'completed' | 'failed' | 'skipped' | 'paused';

export type Participant = {
  user_id: string;
  created_at: string;
  type: 'viewer' | 'participant';
};

export type StepDuration = {
  started_at: string;
  duration: string;
};

export type StepTiming = {
  started_at: string;
  time_left: string;
  time_remaining: string;
  completed: boolean;
};

/*
 * =============================================================================
 * Test Conditon Matrix Types
 * =============================================================================
 */

export enum ProcedureType {
  TestPlan = 'testPlan',
  TestSequence = 'testSequence',
}

/*
 * =============================================================================
 * Automation Types
 * =============================================================================
 */
export type AutomationStatus = 'planned' | 'running' | 'paused' | 'complete';

export interface IRedlineId {
  redline_id?: string;
  /** @deprecated */
  redlineId?: string;
}
