import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import SubstepNumber from '../../../components/SubstepNumber';
import { generateHiddenClassString } from '../../../lib/styles';
import { Checkbox, Select } from '@mui/material';
import { WithDiffChange } from 'shared/lib/types/views/procedures';
import { TestCaseBlock } from '../TestCasesBlock';
import { TestCase } from '../../types';
import { capitalizeFirstLetter } from 'shared/lib/text';
import AssessmentIndicator from '../AssessmentIndicator';
import usePopup from '../../../hooks/usePopup';
import UnitDisplay from '../../../components/Settings/Units/UnitDisplay';
import React from 'react';
import HazardPopup from '../HazardPopup';

type TestCaseDiffElement = WithDiffChange<TestCase>;

export type TestCasesBlockDiffElement = WithDiffChange<
  | TestCaseBlock
  | {
      id: string;
      items: Array<TestCaseDiffElement>;
    }
>;

interface TestCasesProps {
  content: TestCasesBlockDiffElement;
  isHidden: boolean;
  blockLabel: string;
}

const ReviewTestCasesBlock = ({ content, isHidden, blockLabel }: TestCasesProps) => {
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();

  const columnHeaders: string[] = [
    ...(content.items as Array<TestCaseDiffElement>).reduce((acc, testCase) => {
      testCase.test_case_conditions.forEach((condition) => acc.add(condition.name));
      return acc;
    }, new Set<string>()),
  ];

  const getStyleByDiffChangeState = (item, isLink) =>
    item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ? 'bg-emerald-100 text-emerald-800'
      : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ? 'bg-red-100 line-through text-red-600'
      : `bg-white ${isLink ? 'text-blue-600' : ''}`;

  return (
    <>
      <div className={generateHiddenClassString('', isHidden)} />
      <div className={generateHiddenClassString('mt-3 ml-4 mr-4 flex flex-wrap page-break', isHidden)}>
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        <DiffContainer
          label="Test Point"
          diffChangeState={diffUtil.getDiffChangeStateForAddedRemovedOnly(content)}
          isTextSticky={false}
          width="full"
        >
          <table className="w-full border-collapse">
            <thead>
              <tr className="items-center">
                {/* Adjust each header cell with truncate and a set max-width */}
                <td className="border border-gray-400 p-1 max-w-xs">
                  <div className="truncate">
                    <span className="field-title">Test Point</span>
                  </div>
                </td>
                {columnHeaders.map((header, index) => (
                  <td key={index} className="border border-gray-400 p-1 max-w-xs">
                    <div className="truncate">
                      <span className="field-title">{capitalizeFirstLetter(header)}</span>
                    </div>
                  </td>
                ))}
                <td className="border border-gray-400 p-1 max-w-xs">
                  <div className="truncate">
                    <span className="field-title">Hazard</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Started</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Ended</span>
                  </div>
                </td>
                <td className="border border-gray-400 p-1 min-w-[75px]">
                  <div className="truncate text-left">
                    <span className="field-title">Quality</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {content?.items.map((testCase) => (
                <tr
                  key={testCase.id}
                  className={`h-10 text-sm border items-center ${getStyleByDiffChangeState(testCase, false)}`}
                >
                  <td className="border border-gray-400 p-1 max-w-xs">
                    <div className="break-words">
                      <label className={`text-sm truncate ${getStyleByDiffChangeState(testCase, false)}`}>
                        {testCase.name}
                      </label>
                    </div>
                  </td>
                  {columnHeaders.map((header) => {
                    const condition = testCase.test_case_conditions.find((cond) => cond.name === header);
                    return (
                      <td key={header} className="border border-gray-400 p-1 max-w-xs">
                        <div className="truncate break-words">
                          {condition ? (
                            <div className="flex flex-row gap-x-1">
                              {`${condition.value} `}
                              <UnitDisplay unit={condition.units} />
                            </div>
                          ) : null}
                        </div>
                      </td>
                    );
                  })}
                  {/* Display Test Point Hazard with truncate */}
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {testCase?.hazards &&
                      testCase?.hazards.map((hazard) => {
                        return (
                          <div key={hazard.id} className="flex space-x-2 items-center truncate break-words">
                            <AssessmentIndicator hazard={hazard} />
                            <span
                              className={`text-sm hover:underline truncate ${getStyleByDiffChangeState(
                                testCase,
                                true
                              )}`}
                              onClick={(e) => handlePopupOpen(e, hazard.id)}
                            >
                              {hazard.name}
                            </span>
                            <HazardPopup
                              isPopUpShown={activeChildId === hazard.id}
                              onPopupClose={handlePopupClose}
                              hazard={hazard}
                            />
                          </div>
                        );
                      })}
                  </td>
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {/* Start Time */}
                    <div className="flex items-center">
                      <div className="ml-1">
                        <Checkbox disabled={true} size="small"></Checkbox>
                      </div>
                    </div>
                  </td>
                  <td className="border border-gray-400 p-1 max-w-xs">
                    {/* End Time */}
                    <div className="flex items-center">
                      <div className="ml-1">
                        <Checkbox disabled={true} size="small"></Checkbox>
                      </div>
                    </div>
                  </td>
                  <td className="border border-gray-400 max-w-xs">
                    <div className="flex justify-center">
                      <Select value="" label="Quality" size="small" disabled={true}></Select>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DiffContainer>
      </div>
    </>
  );
};

export default ReviewTestCasesBlock;
