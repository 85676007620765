import { useMemo } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import telemetryUtil from '../../lib/telemetry';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';
import { RangeDiffElement } from 'shared/lib/types/views/procedures';

const ReviewBlockTelemetry = ({ telemetry, blockLabel, docState, isHidden, isSpacerHidden }) => {
  const telemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'new')
    ? sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'new').toLowerCase()
    : null;
  const telemetryUnits = sharedDiffUtil.getDiffValue<string | undefined>(telemetry, 'units', 'new');
  const telemetryValue = sharedDiffUtil.getDiffValue<string>(telemetry, 'value', 'new');
  const telemetryRule = sharedDiffUtil.getDiffValue<string>(telemetry, 'rule', 'new').toLowerCase();
  const telemetryRange = diffUtil.getFieldValue(telemetry, 'range') as RangeDiffElement;
  const telemetryMin = telemetryRange ? sharedDiffUtil.getDiffValue(telemetryRange, 'min', 'new') : null;
  const telemetryMax = telemetryRange ? sharedDiffUtil.getDiffValue(telemetryRange, 'max', 'new') : null;
  const telemetryExpression = sharedDiffUtil.getDiffValue(telemetry, 'expression', 'new');

  const displayKey = useMemo(() => {
    if (!telemetryKey) {
      return;
    }
    if (telemetryKey === 'custom') {
      return telemetry.expression;
    }

    const unitsSuffix = telemetryUnits ? ` (${telemetryUnits})` : '';
    return `${telemetryUtil.getParameterName(telemetry)}${unitsSuffix}`;
  }, [telemetry, telemetryKey, telemetryUnits]);

  return (
    <>
      <tr>
        <td>
          <div className={generateHiddenClassString('', isHidden)}></div>
        </td>
        <td colSpan={2}>
          {telemetry.key && (
            <div className={generateHiddenClassString('flex items-start mt-2 mr-8 page-break', isHidden)}>
              <Spacer isHidden={isSpacerHidden} />
              <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={false} />
              <div>
                <DiffContainer label="Telemetry" diffChangeState={telemetry.diff_change_state} isTextSticky={false}>
                  {telemetryKey === 'custom' ? (
                    <span>{telemetryExpression}</span>
                  ) : telemetryRule === 'range' ? (
                    <span>
                      {telemetryMin} {'<'} {displayKey} {'<'} {telemetryMax}
                    </span>
                  ) : (
                    <span>
                      {displayKey} {telemetryRule} {telemetryValue}
                    </span>
                  )}
                </DiffContainer>
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default ReviewBlockTelemetry;
