import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import virtualizationUtil from './lib/virtualizationUtil';

interface VirtualizedElementProps {
  optimize: boolean;
  scrollElementId: string;
  children: ReactNode;
  onRefChanged?: (element: HTMLElement) => void;
}

const VirtualizedElement = ({ optimize, scrollElementId, children, onRefChanged }: VirtualizedElementProps) => {
  const containerRef = useRef<HTMLElement | null>(null);

  const [isElementInViewport, setIsElementInViewport] = useState(false);
  const [containerDimensions, setContainerDimensions] = useState<DOMRect | null>(null);

  const updateRef = useCallback(
    (element) => {
      containerRef.current = element;
      if (onRefChanged) {
        onRefChanged(element);
      }
    },
    [onRefChanged]
  );

  useEffect(() => {
    if (!optimize) {
      return;
    }

    const scrollElement = document.getElementById(scrollElementId);
    const updateIsElementInViewPort = () => {
      if (!containerRef.current) {
        return;
      }

      requestAnimationFrame(() => {
        setIsElementInViewport(() => {
          const newValue = virtualizationUtil.isElementInViewport(containerRef.current);
          return newValue;
        });
      });
    };

    if (containerRef.current) {
      setContainerDimensions(containerRef.current.getBoundingClientRect());
      setIsElementInViewport(virtualizationUtil.isElementInViewport(containerRef.current));

      if (scrollElement) {
        scrollElement.addEventListener('scroll', updateIsElementInViewPort);
      }
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', updateIsElementInViewPort);
      }
    };
  }, [optimize, scrollElementId]);

  return (
    <div ref={updateRef}>
      {!optimize && <>{children}</>}

      {optimize && (
        <>
          {containerDimensions && !isElementInViewport && (
            <div style={{ minHeight: containerDimensions.height, minWidth: containerDimensions.width }}></div>
          )}
          {(!containerDimensions || isElementInViewport) && <>{children}</>}
        </>
      )}
    </div>
  );
};

export default VirtualizedElement;
