import Button from './Button';
import ActivityDisplay from './ActivityDisplay';
import React, { useMemo, useState } from 'react';
import { UserActivity } from './ActivityDisplay/types';
import { Mention } from 'shared/lib/types/postgres/util';
import { CommentInterface } from 'shared/lib/comment';
import { Dialog } from 'primereact/dialog';
import { ReactComponent as Comment } from '../images/comment.svg';
import { ReactComponent as Comment1 } from '../images/comment_1.svg';
import { ReactComponent as Comment2 } from '../images/comment_2.svg';
import { ReactComponent as Comment3 } from '../images/comment_3.svg';
import { ReactComponent as Comment4 } from '../images/comment_4.svg';
import { ReactComponent as Comment5 } from '../images/comment_5.svg';
import { ReactComponent as Comment6 } from '../images/comment_6.svg';
import { ReactComponent as Comment7 } from '../images/comment_7.svg';
import { ReactComponent as Comment8 } from '../images/comment_8.svg';
import { ReactComponent as Comment9 } from '../images/comment_9.svg';
import { ReactComponent as Comment9Plus } from '../images/comment_9plus.svg';
import Pluralize from 'pluralize';

const COMMENT_ICON_MAP = [
  Comment,
  Comment1,
  Comment2,
  Comment3,
  Comment4,
  Comment5,
  Comment6,
  Comment7,
  Comment8,
  Comment9,
];
const getCommentIcon = (count: number) => {
  return count <= 9 ? COMMENT_ICON_MAP[count] : Comment9Plus;
};

interface CommentButtonProps {
  comments?: Array<UserActivity>;
  saveTextComment?: (newCommentText: string, mentions: Array<Mention>) => Promise<void>;
  saveEditComment?: (editedComment: CommentInterface, commentId?: string) => Promise<void>;
  showNewCommentIcon?: boolean;
  size?: 'full';
  onClick?: () => void;
  isDisabled?: boolean;
  contextTitle?: string;
}

const CommentButton = ({
  comments,
  saveTextComment,
  saveEditComment,
  showNewCommentIcon,
  size,
  onClick,
  isDisabled = false,
  contextTitle = '',
}: CommentButtonProps) => {
  const [showCommentModal, setShowCommentModal] = useState(false);

  const closeModal = () => {
    setShowCommentModal(false);
  };

  const { hasComments, commentIcon, commentLabel } = useMemo(() => {
    const commentNumber = comments ? comments.length : 0;
    return {
      hasComments: commentNumber > 0,
      commentIcon: getCommentIcon(commentNumber),
      commentLabel: commentNumber <= 9 ? Pluralize('comment', commentNumber, true) : 'more than 9 comments',
    };
  }, [comments]);

  return (
    <div className="relative w-full h-full">
      {(showNewCommentIcon || hasComments) && (
        <Button
          type="tertiary"
          SvgIcon={commentIcon}
          ariaLabel={commentLabel}
          onClick={onClick ?? (() => setShowCommentModal(true))}
          width={size}
          height={size}
          size="xl"
          removePadding={true}
          isDisabled={isDisabled}
        />
      )}
      {showCommentModal && (
        <Dialog
          visible={Boolean(showCommentModal)}
          header={`Comments${contextTitle && ` for ${contextTitle}`}`}
          onHide={closeModal}
          closeOnEscape={false}
          className="w-3/4 md:w-1/2"
        >
          <ActivityDisplay
            activities={comments ?? []}
            activityAscending={true}
            onAddComment={saveTextComment}
            onEditComment={saveEditComment}
            enableScrolling={true}
            autoFocus={true}
          />
        </Dialog>
      )}
    </div>
  );
};

export default CommentButton;
