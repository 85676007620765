import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import {
  IssueDetail,
  InsertIssueDetail,
} from 'shared/lib/types/postgres/issues';

class IssueDetailsService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/issue-details`;
  }
  async listIssueDetails(): Promise<Array<IssueDetail>> {
    const response = await superlogin.getHttp().get(this.restUrl);
    return response.data.details;
  }

  async createIssueDetail(
    issueDetail: InsertIssueDetail
  ): Promise<IssueDetail> {
    const response = await superlogin.getHttp().post(this.restUrl, issueDetail);
    return response.data;
  }

  async updateIssueDetail(issueDetail: IssueDetail): Promise<IssueDetail> {
    const { id: issueDetailId, ...toUpdate } = issueDetail;
    const url = `${this.restUrl}/${issueDetailId}`;
    const response = await superlogin.getHttp().put(url, toUpdate);
    return response.data;
  }

  async deleteIssueDetail(issueDetailId: number): Promise<void> {
    const url = `${this.restUrl}/${issueDetailId}`;
    await superlogin.getHttp().delete(url);
  }
}

export default IssueDetailsService;
