import { useMemo } from 'react';

interface Props {
  count: number;
  label?: string;
}

/**
 * Component wrapping NotificationBadge to show 9+ when count exceeds 9.
 *
 * @param count - Notification badge count.
 * @param label - Optional label to display instead of count.
 *
 */

export default function SingleDigitNotificationBadge({ count, label }: Props) {
  const showLabel = useMemo(() => label || (count > 9 ? '9+' : String(count)), [label, count]);
  if (!count) {
    return null;
  }

  return (
    <div className="relative w-full h-full">
      <span
        style={{
          display: 'inline-block',
          position: 'absolute',
          minWidth: '15px',
          padding: '3px 4px',
          fontSize: '8px',
          fontWeight: '700',
          lineHeight: '1',
          borderRadius: '10px',
          top: '-2px',
          right: '-2px',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
        className="bg-red-700 text-white"
      >
        {showLabel}
      </span>
    </div>
  );
}
