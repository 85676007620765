import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from '../components/DateTimeDisplay';
import ResultsLabel from '../components/ResultsLabel';
import SubstepNumber from '../components/SubstepNumber';
import RunPlotIcon from '../storage/components/RunPlotIcon';
import { useSettings } from '../contexts/SettingsContext';
import TruncateLeft from '../elements/TruncateLeft';

export type ProcessedSample = {
  streamingStatus: string;
  value: JSX.Element;
  pass: boolean;
  timestamp: string;
};

interface TelemetryRowProps {
  name: string;
  blockLabel?: string;
  sample: ProcessedSample;
  isAggregate: boolean;
  isChildRow: boolean;
  canBeStale: boolean;
  isRecorded: boolean;
  isRunning: boolean;
  showNavigateToPlot: boolean;
  isPlotShown: boolean;
  togglePlotShown: () => void;
}

const timeFormat = (timestamp, isRecorded) => {
  if (isRecorded) {
    return (
      <TruncateLeft>
        <DateTimeDisplay timestamp={timestamp} />
      </TruncateLeft>
    );
  }
  return timestamp ? new Date(timestamp).toLocaleTimeString() : '';
};

const getStatusIcon = (streamingStatus) => {
  const icon = streamingStatus === 'No Signal' ? 'satellite-dish' : 'clock';
  const color =
    streamingStatus === 'Active' ? 'text-blue-500' : streamingStatus === 'Stale' ? 'text-orange-500' : 'text-gray-500';

  return <FontAwesomeIcon className={`mt-1 fa-xs ${color}`} icon={icon} />;
};

const TelemetryRow = ({
  name,
  blockLabel,
  sample,
  isAggregate,
  isChildRow,
  canBeStale,
  isRecorded,
  isRunning,
  showNavigateToPlot,
  isPlotShown,
  togglePlotShown,
}: TelemetryRowProps) => {
  const { isStorageEnabled } = useSettings();

  const showRunPlotIcon = showNavigateToPlot && isStorageEnabled();

  const verticalPadding = isChildRow ? 'my-0' : 'my-1.5';

  return (
    <div className={`flex flex-row ml-4 mr-8 items-start ${verticalPadding}`}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <div className="flex flex-row gap-x-2 w-full mt-0.5">
        <div className="flex w-full justify-between gap-x-1">
          <div className="flex w-full gap-x-1">
            <div
              className={`break-words leading-tight w-5/12 ${isAggregate && !isChildRow ? 'font-semibold' : ''} ${
                isChildRow && 'pl-6'
              }`}
            >
              {name}
            </div>
            <div className="truncate w-4/12 flex justify-end gap-x-1">{sample.value}</div>
            <div className="justify-center w-1/12">
              {sample.pass !== undefined && sample.pass !== null && (
                <ResultsLabel success={sample.pass} successText="Pass" failText="Fail" />
              )}
            </div>
            <div className="truncate w-2/12">{!isChildRow && timeFormat(sample.timestamp, isRecorded)}</div>
            {isRunning && (
              <>
                {!isChildRow && canBeStale && getStatusIcon(sample.streamingStatus)}
                <div className="pl-1 truncate hidden w-1/12 2xl:flex">
                  {!isChildRow && canBeStale && sample.streamingStatus}
                </div>
              </>
            )}
          </div>
          <div className="w-4">
            {showRunPlotIcon && <RunPlotIcon isPlotShown={isPlotShown} onClick={togglePlotShown} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelemetryRow;
