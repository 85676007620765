import { FormikHelpers, FormikValues, getIn, setIn } from 'formik';
import { OperationFieldName } from '../lib/types';
import { DraftTelemetryBlock, Rule } from 'shared/lib/types/views/procedures';

interface FieldSetMathOperationProps {
  path: string;
  rule?: DraftTelemetryBlock | Rule;
  operationFieldName: OperationFieldName;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  operatorError?: string;
  isDisabled?: boolean;
  hasDefaultValue?: boolean;
}

// Renders dropdown for math operations
const FieldSetMathOperation = ({
  path,
  rule,
  operationFieldName,
  setFieldValue,
  operatorError,
  isDisabled,
  hasDefaultValue,
}: FieldSetMathOperationProps) => {
  const onChangeRule = (e) => {
    const op: string = e.target.value;
    let ruleCopy = setIn(rule, `${operationFieldName}`, op);

    // Reset `range` and `value` whenever value/range form element visibilities change
    if (op.toLowerCase() === 'range') {
      ruleCopy = setIn(ruleCopy, 'range', {
        min: '',
        max: '',
      });

      ruleCopy = setIn(ruleCopy, 'value', '');
    } else {
      // Reset the range values when the operation is not a range; otherwise the range values will persist across releases.
      ruleCopy = setIn(ruleCopy, 'range', { min: '', max: '' });
    }

    setFieldValue(path, ruleCopy);
  };

  return (
    <div className="flex flex-col mr-2">
      <span className="field-title">Op</span>
      <select
        placeholder="Condition"
        value={getIn(rule, operationFieldName)}
        data-testid="rule-field"
        onChange={onChangeRule}
        className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-200"
        aria-label="Select Rule"
        disabled={isDisabled}
      >
        {!hasDefaultValue && <option></option>}
        <option>=</option>
        <option>≠</option>
        <option>{'<'}</option>
        <option>{'>'}</option>
        <option>≤</option>
        <option>≥</option>
        <option value="range">Range</option>
      </select>
      {operatorError && <div className="text-red-700">{operatorError}</div>}
    </div>
  );
};

export default FieldSetMathOperation;
