import { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import Select from 'react-select';
import EditSelectClearIndicator from '../../components/EditSelectClearIndicator';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { reactSelectStyles } from '../../lib/styles';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

type InputAction = {
  action: string;
};

type PartOption = {
  value: string;
  label: string;
  part: Part;
};

type SelectData = {
  options: Array<PartOption>;
  isLoading: boolean;
  inputValue: string;
};

type PartSelectProps = {
  part?: Part;
  onChangePart?: (Part) => void;
};

const ExternalPartSelect = ({ part, onChangePart }: PartSelectProps) => {
  const { services } = useDatabaseServices();
  const [selectData, setSelectData] = useState<SelectData>({
    options: [],
    isLoading: false,
    inputValue: '',
  });

  const partToOption = (part: Part | undefined): PartOption | null => {
    if (!part) {
      return null;
    }

    return {
      value: part.part_no,
      label: `${part.part_no} ${part.name}`,
      part,
    };
  };

  const selectValue = useMemo((): PartOption | null => partToOption(part), [part]);

  const onChange = (option: PartOption): void => {
    if (!onChangePart) {
      return;
    }
    if (!option || !option.part) {
      onChangePart(null);
      return;
    }
    // Skip onChange when re-selected the same value.
    if (part && part.part_no === option.part.part_no) {
      return;
    }

    onChangePart(option.part);
  };

  const searchPartOptions = useCallback(
    (query: string): Promise<Array<PartOption>> => {
      if (!services.manufacturing) {
        return Promise.resolve([]);
      }
      return services.manufacturing.searchParts(query).then((parts) => parts.map((part) => partToOption(part)));
    },
    [services.manufacturing]
  );

  const loadOptions = useCallback(
    (inputValue: string): void => {
      searchPartOptions(inputValue).then((options) => {
        setSelectData((data) => {
          if (data.inputValue !== inputValue) {
            return data;
          }
          return {
            options,
            inputValue,
            isLoading: false,
          };
        });
      });
    },
    [searchPartOptions]
  );

  const debouncedLoadOptions = useMemo(() => debounce((query) => loadOptions(query), 300), [loadOptions]);

  const loadOptionsHandler = useCallback(
    (inputValue: string): void => {
      setSelectData({
        options: [],
        inputValue,
        isLoading: true,
      });
      debouncedLoadOptions(inputValue);
    },
    [debouncedLoadOptions]
  );

  const onInputChange = useCallback(
    (inputValue: string, { action }: InputAction): void => {
      // See https://react-select.com/props#statemanager-props
      if (action !== 'input-change') {
        return;
      }
      loadOptionsHandler(inputValue);
    },
    [loadOptionsHandler]
  );

  const onMenuOpen = useCallback(() => loadOptionsHandler(''), [loadOptionsHandler]);

  return (
    <div className="w-96 flex-col items-start">
      <div className="field-title">Part for Recording Usage</div>
      <Select
        styles={reactSelectStyles}
        classNamePrefix="react-select"
        components={{ ClearIndicator: EditSelectClearIndicator }}
        isLoading={selectData.isLoading}
        options={selectData.isLoading ? [] : selectData.options}
        onChange={onChange}
        onInputChange={onInputChange}
        onMenuOpen={onMenuOpen}
        placeholder="Search parts"
        value={selectValue}
        isClearable={true}
        defaultOptions
      />
    </div>
  );
};

export default ExternalPartSelect;
