import { SimulatedFields } from 'shared/lib/telemetry';
import { isSupportedOperation, evaluate, evaluateRangeExclusive } from 'shared/lib/math';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { isNil } from 'lodash';

export const PARAMETER_TYPES = Object.freeze(['float', 'int', 'string', 'bool']);
// https://www.postgresql.org/docs/9.1/datatype-boolean.html
export const BOOL_TRUE = [true, 1, 't', 'true', 'y', 'yes', 'on', '1'];
export const BOOL_FALSE = [false, 0, 'f', 'false', 'n', 'no', 'off', '0'];

const telemetryUtil = {
  /**
   * Returns true if this telemetry represents a simulated parameter. Simulated
   * parameters require special treatment and are generated on the frontent.
   * The current list is in `telemetry/simulation.js`. In the future, simulated
   * parameters can be merged into standard parameters with backend support.
   *
   * telemetry: A telemetry block object.
   * returns: True if this telemetry object represents a simulated parameter,
   *          otherwise false.
   */
  isSimulatedParameter: (telemetry) => {
    if (!telemetry || !telemetry.key) {
      return false;
    }
    const name = telemetryUtil.getParameterName(telemetry);
    return !isNil(name) && name in SimulatedFields;
  },

  /**
   * Returns true if this telemetry represents a custom parameter. Right now,
   * a custom telemetry parameter means it's a boolean expression and
   * that the expression is stored in the `expression` property.
   *
   * telemetry: A telemetry block object.
   * returns: True if this telemetry object represents a custom parameter
   *          (expression), otherwise false.
   */
  isCustomParameter: (telemetry) => {
    if (!telemetry || !telemetry.key) {
      return false;
    }
    const telemetryKey = sharedDiffUtil.getDiffValue(telemetry, 'key', 'new') || '';
    return typeof telemetryKey === 'string' && telemetryKey.toLowerCase() === 'custom';
  },

  /**
   * Returns true if the telemetry object represents a 'standard' parameter.
   *
   * If a telemetry does not represent a simulated or custom parameter (expression), then it is a 'standard'
   * parameter. Standard parameters are available through the API and represent
   * the future architecture of telemetry parameters.
   *
   * telemetry: A telemetry block object.
   * returns: True if this telemetry object represents a standard parameter,
   *          otherwise false.
   */
  isStandardTelemetry: (telemetry) => {
    if (!telemetry || !telemetry.key) {
      return false;
    }
    return !(telemetryUtil.isSimulatedParameter(telemetry) || telemetryUtil.isCustomParameter(telemetry));
  },

  /**
   * Gets the parameter name from a telemetry block. Handles backwards
   * compatibility with `key` and `name` issues.
   *
   * telemetry: A Telemetry Block object.
   * returns: String, Name of parameter used in this telemetry block, or null
   *          if this block does not represent a named parameter.
   */
  getParameterName: (telemetry) => {
    const telemetryNameMaybeString = sharedDiffUtil.getDiffValue(telemetry, 'name', 'new');
    const telemetryName =
      typeof telemetryNameMaybeString === 'string' && telemetryNameMaybeString ? telemetryNameMaybeString : '';
    const telemetryKeyMaybeString = sharedDiffUtil.getDiffValue(telemetry, 'key', 'new');
    const telemetryKey =
      typeof telemetryKeyMaybeString === 'string' && telemetryKeyMaybeString ? telemetryKeyMaybeString : '';
    // Check for newer parameter definition and use `name` property.
    if (telemetryKey.toLowerCase() === 'parameter') {
      return telemetryName;
    }
    /*
     * COSMOS is deprecated in this codebase. This is just a stub to support legacy COSMOS data.
     * Fallback to `cosmos` definition if defined.
     */
    if (telemetryKey.toLowerCase() === 'cosmos') {
      if (telemetryName) {
        return telemetryName;
      }
      const cosmos = telemetry.cosmos;
      if (cosmos.target && cosmos.packet && cosmos.item) {
        return `${cosmos.target}.${cosmos.packet}.${cosmos.item}`;
      }
    }
    if (telemetryKey.toLowerCase() === 'custom') {
      return null;
    }
    // Fallback to whatever is in newer `name` property if defined.
    if (telemetryName) {
      return telemetryName;
    }
    // Fallback to old `key` property as last resort (old simulated telemetry).
    return telemetryKey;
  },

  getParameterIdentifier: (telemetry) => {
    if (!telemetry.dictionary_id) {
      return telemetryUtil.getParameterName(telemetry) || '';
    }
    return `${telemetryUtil.getParameterName(telemetry)}:${telemetry.dictionary_id}`;
  },

  getParameterIdentifierFromSample: (sample) => {
    if (!sample.value.dictionary_id) {
      return sample.value.name;
    }
    return `${sample.value.name}:${sample.value.dictionary_id}`;
  },

  // Temporary hack to maintain broken expressions that don't work with dictionaries
  getNameFromId: (id) => {
    if (!id.includes(':')) {
      return id;
    }
    return id.split(':')[0];
  },

  /**
   * Parses a telemetry value into its corresponding Javascript value.
   *
   * type: String, one of the allowed API types in PARAMETER_TYPES.
   * value: Any, will attempt corresponding Javascript conversion.
   * returns: The corresponding Javascript value, or null if parsing failed.
   */
  parseValue: (type, value) => {
    let parsed;
    switch (type) {
      case 'string': {
        return `${value}`;
      }
      case 'float': {
        parsed = parseFloat(value);
        return isNaN(parsed) ? null : parsed;
      }
      case 'int': {
        parsed = parseInt(value);
        return isNaN(parsed) ? null : parsed;
      }
      case 'enum': {
        parsed = parseInt(value);
        return isNaN(parsed) ? value : parsed;
      }
      case 'bool': {
        const converted = typeof value === 'string' ? value.toLowerCase() : value;
        if (BOOL_TRUE.includes(converted)) {
          return true;
        } else if (BOOL_FALSE.includes(converted)) {
          return false;
        } else {
          return null;
        }
      }
      default: {
        return null;
      }
    }
  },

  /**
   * Returns true if the given value passes the given telemetry rule.
   *
   * Handles parsing of all values involved into their corresponding Javascript
   * value types. Hence, `value`, `telemetry.value` and etc may be represented
   * as strings or other types when passing in. The correct type is obtained
   * from the parameter `type` property.
   *
   * telemetry: A telemetry object containing a rule definition.
   * parameter: A telemetry parameter object containing a `type` property.
   * value: String, number, or boolean representation of current sample
   *        (reading) value.
   * returns: True if the given value passes the telemetry rule, otherwise false.
   *          Throws an error if telemetry or parameter objects are missing.
   *          Returns null if the given value failed parsing or validation.
   */
  isValuePassing: (telemetry, parameter, value) => {
    if (!telemetry) {
      throw new Error('Missing telemetry definition');
    }
    if (!parameter || !parameter.type) {
      throw new Error('Missing parameter type');
    }
    if (value === null || value === undefined || !telemetry.rule) {
      return null;
    }

    // Parse values
    let _value = telemetryUtil.parseValue(parameter.type, value);
    if (_value === null) {
      return null;
    }
    if (isSupportedOperation(telemetry.rule)) {
      const telemetryRHS = telemetryUtil.parseValue(parameter.type, telemetry.value);
      if (telemetryRHS === null) {
        return null;
      }

      // if comparing to enum string value, convert value to string value
      if (parameter.type === 'enum' && typeof telemetryRHS === 'string') {
        _value = parameter.values[_value];
      }

      return evaluate(_value, telemetryRHS, telemetry.rule);
    } else if (telemetry.rule.toLowerCase() === 'range') {
      const telemetryMax = telemetryUtil.parseValue(parameter.type, telemetry.range.max);
      const telemetryMin = telemetryUtil.parseValue(parameter.type, telemetry.range.min);
      if (
        typeof telemetryMax !== 'number' ||
        isNaN(telemetryMax) ||
        typeof telemetryMin !== 'number' ||
        isNaN(telemetryMin)
      ) {
        return null;
      }
      const range = {
        min: telemetryMin,
        max: telemetryMax,
      };
      return evaluateRangeExclusive(_value, range);
    }
    return null;
  },
};

export default telemetryUtil;
