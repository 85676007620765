import {
  Signoff,
  SignoffDiffElement,
  Step,
  StepAction,
  StepRevokeSignoffAction,
  StepSignoffAction,
  TableAction,
  TableRevokeSignoffAction,
} from './types/views/procedures';

type SignoffAction = StepSignoffAction | StepRevokeSignoffAction;

type SignoffActionTypeObjectT = {
  SIGNOFF: 'signoff';
  REVOKE_SIGNOFF: 'revoke_signoff';
};

interface Signoffable {
  signoffs: Array<Signoff>;
  actions?: Array<StepAction | (TableAction & { signoff_id: string })>;
}

type SignoffActionTypeListT =
  SignoffActionTypeObjectT[keyof SignoffActionTypeObjectT];

type NotEmpty = <TValue>(value: TValue | null | undefined) => boolean;

type GroupBy = (
  array: Array<SignoffAction>,
  key: string
) => { [key: string]: Array<SignoffAction> };

type GetSignoffActionGroupsFunc = (
  stepActions: Array<StepAction>
) => ReturnType<GroupBy>;

type GetLatestSignoffActionFunc = (
  actionsForSignoff: Array<StepAction | TableAction>,
  signoffId: string
) => SignoffAction | undefined;

type GetCompleteSignoffActionsFunc = (step: Step) => Array<SignoffAction>;

/**
 * The generate functions in signoffUtilLib are used to create functions for use in views.
 *
 * IMPORTANT: If anything in signoffUtilLib changes, a migration to apply design docs must be run
 * in order to update the views it is used in.
 */
interface SignoffUtilLib {
  SIGNOFF_ACTION_TYPE: SignoffActionTypeObjectT;

  notEmpty: NotEmpty;

  groupBy: GroupBy;

  generateGetSignoffActionGroupsFunc: ({
    signoffActionTypeList,
    groupBy,
  }: {
    signoffActionTypeList: Array<SignoffActionTypeListT>;
    groupBy: GroupBy;
  }) => GetSignoffActionGroupsFunc;

  generateGetLatestSignoffActionFunc: ({
    signoffActionTypeList,
  }: {
    signoffActionTypeList: Array<SignoffActionTypeListT>;
  }) => GetLatestSignoffActionFunc;

  generateGetCompleteSignoffActionsFunc: ({
    signoffActionType,
    getSignoffActionGroups,
    getLatestSignoffAction,
    notEmpty,
  }: {
    signoffActionType: SignoffActionTypeObjectT;
    getSignoffActionGroups: GetSignoffActionGroupsFunc;
    getLatestSignoffAction: GetLatestSignoffActionFunc;
    notEmpty: NotEmpty;
  }) => GetCompleteSignoffActionsFunc;

  getSignoffUtilStringForView: () => string;
}
export const signoffUtilLib: SignoffUtilLib = {
  SIGNOFF_ACTION_TYPE: {
    SIGNOFF: 'signoff',
    REVOKE_SIGNOFF: 'revoke_signoff',
  },

  /**
   * (Copied from collections.ts so that all required logic for this lib is internal)
   * Predicate to use to filter a list of type `T | undefined` to a list of type `T`.
   *
   * Usage: `col.filter(notEmpty)`
   *
   * Modified from {@link https://github.com/microsoft/TypeScript/issues/45097#issuecomment-882526325}.
   */
  notEmpty: (value) => {
    return value !== null && value !== undefined;
  },

  /**
   * Custom function to group arrays. It lives here so that all required logic for this lib is internal.
   */
  groupBy: (array, key) => {
    return array.reduce((groups, element) => {
      const groupKey = element[key];
      if (groupKey) {
        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }
        groups[groupKey].push(element);
      }
      return groups;
    }, {});
  },

  /**
   * Generates a function that returns groupings of signoff actions, grouped by signoff id
   */
  generateGetSignoffActionGroupsFunc: ({ signoffActionTypeList, groupBy }) => {
    return (stepActions) => {
      const signoffActions = stepActions.filter((a): a is SignoffAction =>
        signoffActionTypeList.includes((a as SignoffAction).type)
      );
      return groupBy(signoffActions, 'signoff_id');
    };
  },

  /**
   * Generates a function that can get the latest signoff action
   */
  generateGetLatestSignoffActionFunc: ({ signoffActionTypeList }) => {
    // this needs to be inlined so that this can get toStringed() for design docs
    return (actionsForSignoff = [], signoffId) => {
      const signoffActionsLatestToEarliest = actionsForSignoff
        .filter(
          (a): a is SignoffAction =>
            signoffActionTypeList.includes((a as SignoffAction).type) &&
            (a as SignoffAction).signoff_id === signoffId
        )
        .sort((a, b) => {
          const aVal = a.timestamp;
          const bVal = b.timestamp;
          const factor = -1;
          if (aVal && bVal) {
            return aVal.localeCompare(bVal) * factor;
          } else if (aVal) {
            return 1 * factor;
          } else if (bVal) {
            return -1 * factor;
          }
          return 0;
        });
      return signoffActionsLatestToEarliest[0];
    };
  },

  /**
   * Generates a function that can return all complete signoff actions
   */
  generateGetCompleteSignoffActionsFunc: ({
    signoffActionType,
    getSignoffActionGroups,
    getLatestSignoffAction,
    notEmpty,
  }) => {
    return (step) => {
      if (!step.actions || step.actions.length === 0) {
        return [];
      }

      const signoffActionGroups = getSignoffActionGroups(step.actions);

      return step.signoffs
        .map((signoff) => {
          const latestSignoff = getLatestSignoffAction(
            signoffActionGroups[signoff.id],
            signoff.id
          );
          return latestSignoff?.type === signoffActionType.SIGNOFF
            ? latestSignoff
            : undefined;
        })
        .filter(notEmpty) as Array<SignoffAction>;
    };
  },

  getSignoffUtilStringForView: (): string => {
    return `
          exports.SIGNOFF_ACTION_TYPE = ${JSON.stringify(
            signoffUtilLib.SIGNOFF_ACTION_TYPE
          )};
          exports.SIGNOFF_ACTION_TYPE_LIST = ${JSON.stringify(
            Object.values(signoffUtilLib.SIGNOFF_ACTION_TYPE)
          )}
          exports.groupBy = ${signoffUtilLib.groupBy.toString()};
          exports.notEmpty = ${signoffUtilLib.notEmpty.toString()};
          exports.generateGetSignoffActionGroupsFunc = ${signoffUtilLib.generateGetSignoffActionGroupsFunc.toString()};
          exports.generateGetLatestSignoffActionFunc = ${signoffUtilLib.generateGetLatestSignoffActionFunc.toString()};
          exports.generateGetCompleteSignoffActionsFunc = ${signoffUtilLib.generateGetCompleteSignoffActionsFunc.toString()};
        `;
  },
};

/**
 * Have signoff action types live in this file to avoid circular dependency with runUtil.
 */
export const SIGNOFF_ACTION_TYPE = signoffUtilLib.SIGNOFF_ACTION_TYPE;

const SIGNOFF_ACTION_TYPE_LIST = Object.values(SIGNOFF_ACTION_TYPE);

const signoffUtil = {
  /**
   * Get groups of signoff action arrays, keyed by signoff_id.
   */
  _getSignoffActionGroups: ((actions) => {
    const getSignoffActionGroupsFunc =
      signoffUtilLib.generateGetSignoffActionGroupsFunc({
        signoffActionTypeList: SIGNOFF_ACTION_TYPE_LIST,
        groupBy: signoffUtilLib.groupBy,
      });
    return getSignoffActionGroupsFunc(actions);
  }) as GetSignoffActionGroupsFunc,

  getLatestSignoffAction: ((actions = [], signoffId) => {
    const getLatestSignoffActionFunc =
      signoffUtilLib.generateGetLatestSignoffActionFunc({
        signoffActionTypeList: SIGNOFF_ACTION_TYPE_LIST,
      });
    return getLatestSignoffActionFunc(actions, signoffId);
  }) as GetLatestSignoffActionFunc,

  getSignoffActions: (step: Step): Array<StepSignoffAction> => {
    if (!step.actions) {
      return [];
    }
    return step.actions.filter(
      (a): a is StepSignoffAction => a.type === SIGNOFF_ACTION_TYPE.SIGNOFF
    );
  },

  getRevokeSignoffActions: (step: Step): Array<StepRevokeSignoffAction> => {
    if (!step.actions) {
      return [];
    }
    return step.actions.filter(
      (a): a is StepRevokeSignoffAction =>
        a.type === SIGNOFF_ACTION_TYPE.REVOKE_SIGNOFF
    );
  },

  getCompleteSignoffActions: ((step) => {
    const getSignoffActionGroups =
      signoffUtilLib.generateGetSignoffActionGroupsFunc({
        signoffActionTypeList: SIGNOFF_ACTION_TYPE_LIST,
        groupBy: signoffUtilLib.groupBy,
      });

    const getLatestSignoffAction =
      signoffUtilLib.generateGetLatestSignoffActionFunc({
        signoffActionTypeList: SIGNOFF_ACTION_TYPE_LIST,
      });

    const completeSignoffActionsFunc =
      signoffUtilLib.generateGetCompleteSignoffActionsFunc({
        signoffActionType: SIGNOFF_ACTION_TYPE,
        getSignoffActionGroups,
        getLatestSignoffAction,
        notEmpty: signoffUtilLib.notEmpty,
      });

    return completeSignoffActionsFunc(step);
  }) as GetCompleteSignoffActionsFunc,

  allSignoffsComplete: (step: Step): boolean => {
    if (!step.actions || step.actions.length === 0) {
      return false;
    }
    const signoffActionGroups = signoffUtil._getSignoffActionGroups(
      step.actions
    );
    return step.signoffs.every((signoff) =>
      signoffUtil._isSignoffCompletedInActions(
        signoffActionGroups[signoff.id],
        signoff.id
      )
    );
  },

  anySignoffsComplete: (step: Step): boolean => {
    if (!step.actions || step.actions.length === 0) {
      return false;
    }
    const signoffActionGroups = signoffUtil._getSignoffActionGroups(
      step.actions
    );
    return step.signoffs.some((signoff) =>
      signoffUtil._isSignoffCompletedInActions(
        signoffActionGroups[signoff.id],
        signoff.id
      )
    );
  },

  getCompleteSignoffs: (step: Step): Array<Signoff> => {
    if (!step.actions || step.actions.length === 0) {
      return [];
    }
    const signoffActionGroups = signoffUtil._getSignoffActionGroups(
      step.actions
    );
    return step.signoffs.filter((signoff) =>
      signoffUtil._isSignoffCompletedInActions(
        signoffActionGroups[signoff.id],
        signoff.id
      )
    );
  },

  getIncompleteSignoffs: (step: Step): Array<Signoff> => {
    if (!step.actions || step.actions.length === 0) {
      return step.signoffs;
    }
    const signoffActionGroups = signoffUtil._getSignoffActionGroups(
      step.actions
    );
    return step.signoffs.filter(
      (signoff) =>
        !signoffUtil._isSignoffCompletedInActions(
          signoffActionGroups[signoff.id],
          signoff.id
        )
    );
  },

  /**
   * Sort the given actions by latest timestamp, and use the latest action to determine the signoff status.
   */
  _isSignoffCompletedInActions: (
    actions: Array<StepAction | TableAction> = [],
    signoffId: string,
    userId?: string
  ): boolean => {
    const latestAction = signoffUtil.getLatestSignoffAction(actions, signoffId);
    return (
      latestAction !== undefined &&
      latestAction.type === SIGNOFF_ACTION_TYPE.SIGNOFF &&
      (!userId || latestAction.user_id === userId)
    );
  },

  /** @returns whether required signoff is completed */
  isSignoffCompleted: (
    signoffable: Signoffable,
    signoffId: string
  ): boolean => {
    if (!signoffable.actions || signoffable.actions.length === 0) {
      return false;
    }
    return signoffUtil._isSignoffCompletedInActions(
      signoffable.actions,
      signoffId
    );
  },

  isSignOffCompletedByAutomation: (step: Step, signoffId: string): boolean => {
    if (!step.actions || step.actions.length === 0) {
      return false;
    }
    return signoffUtil._isSignoffCompletedInActions(
      step.actions,
      signoffId,
      'Automation'
    );
  },

  getSignoffAction: ({
    userId,
    signoffId,
    timestamp,
    operator,
    conditionalValue,
    deviceUserId,
  }: {
    userId: string;
    signoffId: string;
    timestamp: string;
    operator: string;
    conditionalValue: string | null;
    deviceUserId?: string;
  }): StepSignoffAction => {
    return {
      type: SIGNOFF_ACTION_TYPE.SIGNOFF,
      user_id: userId,
      timestamp,
      signoff_id: signoffId,
      operator,
      ...(conditionalValue && { conditional_value: conditionalValue }),
      ...(deviceUserId && { device_user_id: deviceUserId }),
    };
  },

  getRevokeSignoffAction: ({
    userId,
    signoffId,
    timestamp,
    actions,
  }: {
    userId: string;
    signoffId: string;
    timestamp: string;
    actions: Array<StepAction | TableAction>;
  }): StepRevokeSignoffAction | TableRevokeSignoffAction => {
    const latestSignoffAction = signoffUtil.getLatestSignoffAction(
      actions,
      signoffId
    );
    if (
      !latestSignoffAction ||
      latestSignoffAction.type !== SIGNOFF_ACTION_TYPE.SIGNOFF
    ) {
      throw new Error('Previous action is not a signoff action.');
    }

    // Latest signoff action was a signoff if this point is reached.
    return {
      type: SIGNOFF_ACTION_TYPE.REVOKE_SIGNOFF,
      user_id: userId,
      timestamp,
      signoff_id: signoffId,
      revoked_user_id: latestSignoffAction.user_id,
      revoked_operator: latestSignoffAction.operator,
      ...(latestSignoffAction.conditional_value && {
        revoked_conditional_value: latestSignoffAction.conditional_value,
      }),
    };
  },

  hasOperators: (signoff: Signoff | SignoffDiffElement): boolean =>
    signoff.operators && signoff.operators.length > 0,

  requiresAnyRoles: (signoff: Signoff, roles: Array<string>): boolean =>
    signoffUtil.hasOperators(signoff) &&
    signoff.operators.some((o) => roles.includes(o)),

  getAllOperatorsInSignoffs: (signoffs: Array<Signoff>): Array<string> => {
    if (!signoffs) {
      return [];
    }
    return signoffs.flatMap((signoff) => signoff.operators);
  },

  stringifyAllOperatorsInSignoffs: (signoffs: Array<Signoff>): string => {
    if (!signoffs) {
      return '';
    }

    const operatorsArray: Array<string> = [];
    for (const signoff of signoffs) {
      operatorsArray.push('( ' + signoff.operators?.join(' | ') + ' )');
    }
    return operatorsArray.join(' & ');
  },

  isSignoffRequired: (signoffs: Array<Signoff>): boolean => {
    return signoffs && signoffs.length > 0;
  },

  isGenericSignoffRequired: (signoffs: Array<Signoff>): boolean => {
    if (signoffUtil.isSignoffRequired(signoffs)) {
      if (signoffs.length === 1 && signoffs[0].operators.length === 0) {
        return true;
      }
    }
    return false;
  },

  requiresSpecificOperator: (signoffs: Array<Signoff>): boolean => {
    return (
      signoffUtil.isSignoffRequired(signoffs) &&
      signoffs[0].operators.length > 0
    );
  },
};

export default signoffUtil;
