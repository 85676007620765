import React, { useRef, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalBase from './ModalBase';
import { useHistory } from 'react-router-dom';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import Button, { BUTTON_TYPES } from './Button';
import { procedureImportPath } from '../lib/pathUtil';

const TUTORIAL_URL = 'https://support.epsilon3.io/en/articles/7892897-import-procedure';

/**
 * ImportUploadModal properties.
 *
 * @param onClose - Callback for closing modal in parent.
 */
interface ImportUploadModalProps {
  onClose: () => void;
}

/**
 * Displays a fullscreen, blocking modal prompting for import upload.
 */
const ImportUploadModal = ({ onClose }: ImportUploadModalProps) => {
  const importProcedureInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { services, currentTeamId } = useDatabaseServices();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const { mixpanel } = useMixpanel();

  const onImportProcedureInputChange = useCallback(
    (e) => {
      setIsUploading(true);
      const file = e.currentTarget.files[0];

      if (mixpanel) {
        mixpanel.track('[Import Procedure] File Uploaded', {
          file_type: file.type !== '' ? file.type : file.name.slice(-4),
          file_size: file.size,
        });
      }

      services.procedures
        .importProcedure(file)
        .then((res) => {
          setUploadError('');
          const protoBlockList = res.data.protoblocks;
          const importUUID = res.data.importUUID;

          if (mixpanel) {
            mixpanel.track('[Import Procedure] Initial Protoblock List Generated', {
              n_protoblocks: protoBlockList.length,
              importUUID,
            });
          }

          if (importProcedureInputRef && importProcedureInputRef.current) {
            importProcedureInputRef.current.value = '';
          }
          history.push({
            pathname: procedureImportPath(currentTeamId, importUUID),
            state: {
              importFileName: file.name,
              protoBlockList,
            },
          });
        })
        .catch((err) => {
          setIsUploading(false);
          setUploadError(err.response?.data?.error || 'Error, please try again');
        });
    },
    [history, mixpanel, services.procedures, currentTeamId]
  );

  const onUploadFile = useCallback(() => {
    if (!importProcedureInputRef.current) {
      return;
    }
    importProcedureInputRef.current.click();
  }, []);

  return (
    <ModalBase>
      <FontAwesomeIcon
        onClick={onClose}
        icon="times"
        size="lg"
        className="absolute right-5 top-4 text-gray-200 hover:text-gray-400 cursor-pointer"
      />
      <div className="flex flex-col">
        <h1 className="text-center mt-3">Import Procedure</h1>
        <div className="flex justify-center my-2 mx-2">
          <input
            ref={importProcedureInputRef}
            type="file"
            value=""
            accept=".csv,.prl"
            id="import-procedure"
            onChange={(e) => onImportProcedureInputChange(e)}
            className="hidden"
          />
          <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="upload" onClick={onUploadFile}>
            {isUploading ? 'Uploading...' : 'Upload File'}
          </Button>
        </div>
        {uploadError !== '' && (
          <div className="mx-5 py-2 text-red-900 px-3 bg-red-200 text-center rounded space-x-2">
            <FontAwesomeIcon icon="times-circle" className="mr-2" />
            {uploadError}
          </div>
        )}
        <div className="flex flex-col my-3 mx-4">
          Currently only Pride (.prl) and Comma Separated Value (.csv) formats are supported for import. CSV files can
          be imported using the following format:
          <code>
            <div className="text-sm my-3 p-2 bg-gray-200 overflow-auto whitespace-pre text-right">
              <table>
                <thead>
                  <tr>
                    <th>Step Name,</th>
                    <th>Notes,</th>
                    <th>Inputs,</th>
                    <th>Roles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Section A,</td>
                    <td> ,</td>
                    <td> ,</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> Step A1,</td>
                    <td> Some note,</td>
                    <td> ,</td>
                    <td> OP; TECH</td>
                  </tr>
                  <tr>
                    <td> Step A2,</td>
                    <td> ,</td>
                    <td> Input pressure,</td>
                    <td> FD/MD</td>
                  </tr>
                  <tr>
                    <td> ,</td>
                    <td> ,</td>
                    <td> ,</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> Section B,</td>
                    <td> ,</td>
                    <td> ,</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> Step B1,</td>
                    <td> Another note,</td>
                    <td> Input force,</td>
                    <td> ENG; OP/TECH</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </code>
          <span>
            For more instructions on how to use this feature, check out our
            <a
              href={TUTORIAL_URL}
              className="ml-1 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              tutorial
            </a>
            .
          </span>
        </div>
      </div>
    </ModalBase>
  );
};

export default ImportUploadModal;
