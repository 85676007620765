import {
  FieldInputBlock,
  FieldInputNumberBlock,
  RunFieldInputRecordedValue,
} from 'shared/lib/types/views/procedures';
import { isEmptyValue } from 'shared/lib/text';
import {
  evaluate,
  evaluateRangeExclusive,
  isSupportedOperation,
} from 'shared/lib/math';

const fieldInputUtil = {
  isNumberFieldInputPassing: (
    block: FieldInputBlock,
    recorded?: { value?: RunFieldInputRecordedValue }
  ): null | boolean => {
    const rule = (block as FieldInputNumberBlock).rule;
    if (isEmptyValue(rule)) {
      return null;
    }
    const recordedValue = recorded && recorded.value;
    if (isEmptyValue(recordedValue)) {
      return null;
    }
    const op = (block as FieldInputNumberBlock).rule?.op;
    const value = parseFloat(recordedValue as string);
    if (rule && op && isSupportedOperation(op)) {
      const operand = parseFloat(rule.value as string);
      return evaluate(value, operand, op);
    } else if (rule && op === 'range') {
      const min = rule.range?.min as string;
      const max = rule.range?.max as string;
      if (isEmptyValue(min) || isEmptyValue(max)) {
        return null;
      }

      const range = {
        min: parseFloat(min),
        max: parseFloat(max),
      };
      return evaluateRangeExclusive(value, range);
    }
    return null;
  },
};

export default fieldInputUtil;
