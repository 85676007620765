import { Hazard, AssessmentMatrixMetadata } from 'shared/lib/types/testing';
import apm from '../../lib/apm';
import {
  HEX_BLACK,
  HEX_LIME_500,
  HEX_ORANGE_500,
  HEX_PURPLE_700,
  HEX_RED_500,
  HEX_WHITE,
  HEX_YELLOW_300,
} from '../../lib/styles';
import { isEqual, max, min } from 'lodash';
import {
  DEFAULT_ASSESSMENT_MATRIX_METADATA,
  HAZARD_PREFIX,
} from 'shared/lib/hazards';

const _getHazardRowAndColumnIndices = (
  hazard: Hazard
): { rowIndex: number; columnIndex: number } => {
  const assessmentMatrixMetadata =
    hazard.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA;
  const rowKey = getHazardRowKey(hazard);
  const columnKey = getHazardColumnKey(hazard);

  const rowIndex = assessmentMatrixMetadata.row_labels.findIndex(
    ({ id: rowId }) => rowId === rowKey
  );
  if (rowIndex === -1) {
    throw new Error('Row key not found.');
  }

  const columnIndex = assessmentMatrixMetadata.column_labels.findIndex(
    ({ id: columnId }) => columnId === columnKey
  );
  if (columnIndex === -1) {
    throw new Error('Column key not found');
  }

  return {
    rowIndex,
    columnIndex,
  };
};

const _getHazardParameters = (hazard: Hazard): AssessmentParameters => {
  const assessmentMatrixMetadata =
    hazard.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA;
  const { rowIndex, columnIndex } = _getHazardRowAndColumnIndices(hazard);
  const assessmentLevel =
    assessmentMatrixMetadata.assessment_matrix[rowIndex][columnIndex];
  if (assessmentLevel === undefined) {
    throw new Error('Hazard not found');
  }

  const assessmentParameters = ASSESSMENT_PARAMETERS[assessmentLevel];
  if (!assessmentParameters) {
    throw new Error('Hazard not found');
  }
  return assessmentParameters;
};

/**
 * @returns assessment level, or -1 if there is an error
 */
export const calculateAssessmentLevel = (hazard: Hazard): number => {
  const assessmentMatrixMetadata =
    hazard.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA;

  try {
    const { rowIndex, columnIndex } = _getHazardRowAndColumnIndices(hazard);
    const assessmentLevel =
      assessmentMatrixMetadata.assessment_matrix[rowIndex][columnIndex];
    if (assessmentLevel === undefined) {
      throw new Error('Hazard not found');
    }
    return assessmentLevel;
  } catch (e) {
    apm.captureError(e);
    return -1;
  }
};

export const levelOfAssessment = (hazard: Hazard): number => {
  return calculateAssessmentLevel(hazard);
};

export const getAssessmentColorForCell = (
  assessmentMatrix: AssessmentMatrixMetadata['assessment_matrix'],
  rowIndex: number,
  columnIndex: number
): string => {
  try {
    const assessmentLevel = assessmentMatrix[rowIndex][columnIndex];
    return ASSESSMENT_PARAMETERS[assessmentLevel]?.backgroundColor ?? HEX_WHITE;
  } catch (e) {
    // some potential data issue with matrix definition and row,column
    apm.captureError(e);
    return HEX_WHITE;
  }
};

export type AssessmentColor = {
  backgroundColor: AssessmentParameters['backgroundColor'] | typeof HEX_WHITE;
  backgroundColorName: AssessmentParameters['backgroundColorName'] | '';
};
export const getAssessmentColor = (hazard: Hazard): AssessmentColor => {
  try {
    const assessmentParameters = _getHazardParameters(hazard);
    return {
      backgroundColor: assessmentParameters?.backgroundColor ?? HEX_WHITE,
      backgroundColorName: assessmentParameters?.backgroundColorName ?? '',
    };
  } catch (e) {
    apm.captureError(e);
    return { backgroundColor: HEX_WHITE, backgroundColorName: '' };
  }
};

export const getAssessmentBorderColor = (
  assessmentMatrix: AssessmentMatrixMetadata['assessment_matrix'],
  rowIndex: number,
  columnIndex: number
): string => {
  const assessmentLevel = assessmentMatrix[rowIndex][columnIndex];
  return ASSESSMENT_PARAMETERS[assessmentLevel]?.borderColor ?? '#000000';
};

export const DEFAULT_ASSESSMENT_SEVERITY_TO_ID = {
  0: `${HAZARD_PREFIX}_row_default0`,
  1: `${HAZARD_PREFIX}_row_default1`,
  2: `${HAZARD_PREFIX}_row_default2`,
  3: `${HAZARD_PREFIX}_row_default3`,
  4: `${HAZARD_PREFIX}_row_default4`,
};

export const DEFAULT_ASSESSMENT_PROBABILITY_TO_ID = {
  0: `${HAZARD_PREFIX}_column_default0`,
  1: `${HAZARD_PREFIX}_column_default1`,
  2: `${HAZARD_PREFIX}_column_default2`,
  3: `${HAZARD_PREFIX}_column_default3`,
  4: `${HAZARD_PREFIX}_column_default4`,
};

export const getHazardRowKey = (hazard: Hazard): string => {
  const rowValue = hazard.row_value ?? hazard.severity;
  return typeof rowValue === 'number'
    ? DEFAULT_ASSESSMENT_SEVERITY_TO_ID[rowValue]
    : rowValue;
};

export const getHazardRowName = (hazard: Hazard): string => {
  const rowId = getHazardRowKey(hazard);
  const rowLabel = (
    hazard.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA
  ).row_labels.find(({ id: _rowId }) => _rowId === rowId);

  return rowLabel?.name ?? '';
};

export const getHazardColumnKey = (hazard: Hazard): string => {
  const columnValue = hazard.column_value ?? hazard.probability;
  return typeof columnValue === 'number'
    ? DEFAULT_ASSESSMENT_PROBABILITY_TO_ID[columnValue]
    : columnValue;
};

export const getHazardColumnName = (hazard: Hazard): string => {
  const rowId = getHazardColumnKey(hazard);
  const columnLabel = (
    hazard.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA
  ).column_labels.find(({ id: _rowId }) => _rowId === rowId);

  return columnLabel?.name ?? '';
};

export const ASSESSMENT_PARAMETERS = {
  0: {
    backgroundColor: HEX_LIME_500,
    backgroundColorName: 'green',
    borderColor: HEX_BLACK,
  },
  1: {
    backgroundColor: HEX_YELLOW_300,
    backgroundColorName: 'yellow',
    borderColor: HEX_BLACK,
  },
  2: {
    backgroundColor: HEX_ORANGE_500,
    backgroundColorName: 'orange',
    borderColor: HEX_BLACK,
  },
  3: {
    backgroundColor: HEX_RED_500,
    backgroundColorName: 'red',
    borderColor: HEX_BLACK,
  },
  4: {
    backgroundColor: HEX_PURPLE_700,
    backgroundColorName: 'purple',
    borderColor: HEX_WHITE,
  },
  5: {
    backgroundColor: HEX_BLACK,
    backgroundColorName: 'black',
    borderColor: HEX_WHITE,
  },
} as const;

export type AssessmentParameters =
  (typeof ASSESSMENT_PARAMETERS)[keyof typeof ASSESSMENT_PARAMETERS];

export const MAX_ASSESSMENT_LEVEL = max(
  Object.keys(ASSESSMENT_PARAMETERS).map((s) => parseInt(s, 10))
) as number;

export const MIN_ASSESSMENT_LEVEL = min(
  Object.keys(ASSESSMENT_PARAMETERS).map((s) => parseInt(s, 10))
) as number;

export const getRowIds = (
  assessmentMatrixMetadata?: AssessmentMatrixMetadata
): Array<string> => {
  return (
    assessmentMatrixMetadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA
  ).row_labels.map(({ id }) => id);
};

export const getColumnIds = (
  assessmentMatrixMetadata?: AssessmentMatrixMetadata
): Array<string> => {
  return (
    assessmentMatrixMetadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA
  ).column_labels.map(({ id }) => id);
};

export const assessmentMatrixHasUpdates = (
  settingsAssessmentMatrix?: AssessmentMatrixMetadata,
  assessmentMatrix?: AssessmentMatrixMetadata
): boolean => {
  return Boolean(
    settingsAssessmentMatrix &&
      !isEqual(
        settingsAssessmentMatrix,
        assessmentMatrix ?? DEFAULT_ASSESSMENT_MATRIX_METADATA
      )
  );
};
