import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Hazard } from 'shared/lib/types/testing';
import AssessmentMatrix from './AssessmentMatrix';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASIC_REFRESH_TRY_AGAIN_MESSAGE } from '../../lib/messages';
import { isEmpty } from 'lodash';
import MarkdownView from '../../components/Markdown/MarkdownView';
import Label from '../../components/Label';
import useProjectFilterHelper from '../hooks/useProjectFilterHelper';
import usePopup from '../../hooks/usePopup';
import TestingPopup from '../../components/Popup';
import useHazards from '../hooks/useHazards';
import { getHazardColumnKey, getHazardRowKey, assessmentMatrixHasUpdates } from '../libs/hazards';
import StickyHeader from './StickyHeader';
import { testingHazardsPath, testingHazardsViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import TestCaseView from './TestCaseView';
import { HazardSettingsState } from '../types';
import { DEFAULT_ASSESSMENT_MATRIX_METADATA } from 'shared/lib/hazards';

interface HazardViewDeprecatedProps {
  hazard: Hazard;
  isPopup?: boolean;
  hazardSettings: HazardSettingsState;
}

const HazardView = ({ hazard, isPopup = false, hazardSettings }: HazardViewDeprecatedProps) => {
  const { currentTeamId } = useDatabaseServices();
  const { deleteHazard } = useHazards();
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();
  const [globalErrors, setGlobalErrors] = useState<Error>();
  const history = useHistory();
  const { getProjectName } = useProjectFilterHelper();

  const newMatrixAvailable = useMemo(() => {
    return assessmentMatrixHasUpdates(hazardSettings.assessment_matrix_metadata, hazard.matrix_metadata);
  }, [hazardSettings.assessment_matrix_metadata, hazard.matrix_metadata]);

  const onDeleteHazard = useCallback(() => {
    if (!hazard) {
      setGlobalErrors(new Error(BASIC_REFRESH_TRY_AGAIN_MESSAGE));
      return;
    }
    const prompt = 'This hazard will be permanently removed. Are you sure you want to proceed?';
    if (!window.confirm(prompt)) {
      return;
    }
    deleteHazard(hazard.id)
      .then(() => history.push(testingHazardsPath(currentTeamId)))
      .catch(() => {
        setGlobalErrors(new Error(`Unable to delete hazard. ${BASIC_REFRESH_TRY_AGAIN_MESSAGE}`));
      });
  }, [hazard, deleteHazard, history, currentTeamId]);

  if (hazardSettings.loading) {
    return null;
  }

  return (
    <>
      {!isPopup && (
        <div>
          <StickyHeader
            code=""
            name={hazard.name}
            source={{
              title: 'Hazards',
              onClick: () => history.push(testingHazardsPath(currentTeamId)),
            }}
            showEditDeleteButtons={true}
            onDelete={onDeleteHazard}
            resourcePath={testingHazardsViewPath(currentTeamId, hazard.id)}
            projectId={hazard.project_id}
          />
          <div className="text-lg mt-8">{hazard?.name}</div>
        </div>
      )}
      {globalErrors && <p className="self-center mt-2 text-red-700">Error: {globalErrors.message}</p>}
      {hazard && (
        <>
          <div className="flex flex-row gap-x-4">
            <div className="w-full p-4 bg-white rounded border border-solid border-gray-100 shadow">
              <div className="flex-auto flex flex-col gap-y-8">
                <div className="flex flex-col">
                  <label htmlFor="category" className="text-sm text-gray-500">
                    Category
                  </label>
                  <label className="text-sm text-black">{hazard.category}</label>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="cause" className="text-sm text-gray-500">
                    Cause
                  </label>
                  <label className="text-sm text-black">{hazard.cause}</label>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="effect" className="text-sm text-gray-500">
                    Effect
                  </label>
                  <label className="text-sm text-black">{hazard.effect}</label>
                </div>

                <div className="flex flex-col">
                  <label className="text-sm text-gray-500">Project</label>
                  {hazard.project_id && (
                    <div className="flex flex-grow">
                      <Label text={getProjectName(hazard.project_id) || ''} color="bg-gray-200" />
                    </div>
                  )}
                </div>

                {hazard.details?.map(({ name, value }) => (
                  <>
                    {!isEmpty(value) && (
                      <div key={name} className="flex flex-col">
                        <span className="text-sm text-gray-500">{name}</span>
                        <span className="text-sm text-black">{value}</span>
                      </div>
                    )}
                  </>
                ))}

                {!isPopup && (
                  <div className="flex flex-col">
                    <label htmlFor="test-cases" className="text-sm text-gray-500">
                      Test Points
                    </label>
                    {(!hazard.cases || hazard.cases.length === 0) && (
                      <span className="text-xs text-gray-500">Hazard has no associated cases.</span>
                    )}
                    {hazard.cases &&
                      hazard.cases.length > 0 &&
                      hazard.cases.map((testCase) => (
                        <Fragment key={testCase.id}>
                          <span
                            onClick={(e) => handlePopupOpen(e, testCase.id)}
                            className="text-sm text-blue-600 hover:underline truncate"
                          >
                            {testCase.name}
                          </span>
                          <TestingPopup
                            isPopUpShown={activeChildId === testCase.id}
                            onPopupClose={handlePopupClose}
                            header={testCase.name}
                          >
                            <TestCaseView testCase={testCase} isPopup={true} />
                          </TestingPopup>
                        </Fragment>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full p-4 bg-white rounded border border-solid border-gray-100 shadow">
              <span className="flex flex-col">
                <div className="text-sm text-slate-500 mb-1">Hazard Analysis</div>
                <div
                  className={`flex flex-col ${
                    newMatrixAvailable ? 'gap-y-2 py-2 px-3 mb-2 w-fit rounded border bg-blue-50 border-blue-100' : ''
                  }`}
                >
                  {newMatrixAvailable && (
                    <div className="text-red-600 text-sm flex flex-row items-center gap-x-1">
                      <FontAwesomeIcon icon="exclamation-triangle" />
                      <span>Hazard Analysis Matrix has changes. Edit to update.</span>
                    </div>
                  )}
                  <AssessmentMatrix
                    selectedRowValue={getHazardRowKey(hazard)}
                    selectedColumnValue={getHazardColumnKey(hazard)}
                    assessmentMatrixMetadata={hazard?.matrix_metadata ?? DEFAULT_ASSESSMENT_MATRIX_METADATA}
                    isDisabled={true}
                  />
                </div>
              </span>
            </div>
          </div>
          {hazard.mitigation && (
            <>
              <div className="text-sm text-slate-500 mt-4">Mitigation and Minimizing Procedures</div>
              <div className="w-full p-4 bg-white rounded border border-solid border-gray-100 shadow text-sm text-black">
                <MarkdownView text={hazard.mitigation} />
              </div>
            </>
          )}
          {hazard.notes && (
            <>
              <div className="text-sm text-slate-500 mt-4">Notes</div>
              <div className="w-full p-4 bg-white rounded border border-solid border-gray-100 shadow text-sm text-black">
                <MarkdownView text={hazard.notes} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default HazardView;
