import DataGrid, { RenderSortStatusProps, SortColumn } from 'react-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NotificationRow } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNavState } from '../../contexts/NavContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pluralize from 'pluralize';
import renderDateTime from '../../components/Home/Renderers/DateTime';
import ReviewerNotificationLink from '../../components/Notifications/ReviewerNotificationLink';
import notificationsLib from '../../lib/notifications';
import { useSettings } from '../../contexts/SettingsContext';
import { useUserInfo } from '../../contexts/UserContext';
import { useSelector, useStore } from 'react-redux';
import { selectProceduresMetadata } from '../../contexts/proceduresSlice';
import Label from '../../components/Label';
import ProcedureGenerationNotificationLink from '../../components/Notifications/ProcedureGenerationNotificationLink';
import SearchInput from '../../elements/SearchInput';
import AutomationNotificationLink from '../../components/Notifications/AutomationNotificationLink';
import { NotifyAutomationContext } from 'shared/lib/types/postgres/notifications';
import { selectActiveRunById } from '../../contexts/runsSlice';
import { RUN_STATE } from 'shared/lib/runUtil';
import { useNotifications } from '../../contexts/NotificationContext';
import AtMentionNotificationLink from '../../components/Notifications/AtMentionNotificationLink';
import { Helmet } from 'react-helmet-async';

const NO_SCROLL_PAD = 2; // Adding this to grid height removes the inner vertical scrollbar
const MAIN_VERTICAL_PADDING = 210;

const NotificationsList = () => {
  const { services, currentTeamId } = useDatabaseServices();
  const { users } = useSettings();
  const { userInfo } = useUserInfo();
  const { notifications } = useNotifications();
  const store = useStore();
  const proceduresMetadata = useSelector((state) => {
    const procedures = selectProceduresMetadata(state, currentTeamId);
    return procedures;
  });
  const navState = useNavState();
  const [mainHeight, setMainHeight] = useState<number>(window.innerHeight - MAIN_VERTICAL_PADDING);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([
    {
      columnKey: 'createdAt',
      direction: 'DESC',
    },
  ]);

  const NOTIFICATION_LINK_MAP = useMemo(() => {
    return {
      reviewers: ReviewerNotificationLink,
      procedure_generation: ProcedureGenerationNotificationLink,
      automation: AutomationNotificationLink,
      at_mention: AtMentionNotificationLink,
    };
  }, []);

  const MESSAGE_TYPE_MAP = useMemo(() => {
    return {
      reviewers: 'Review',
      procedure_generation: 'Generated Procedure',
      automation: 'Action',
      at_mention: 'Mention',
    };
  }, []);

  const handleOnClick = useCallback(
    (ids: Array<number>) => {
      if (!services) {
        return;
      }
      services.notifications.resolveNotifications(ids);
    },
    [services]
  );

  const notificationsWithActiveProperty = useMemo(() => {
    for (const notification of notifications) {
      let isActive = false;
      switch (notification.type) {
        case 'reviewers':
          isActive = notificationsLib.isReviewerNotificationActive(notification, userInfo, users, proceduresMetadata);
          break;
        case 'automation': {
          const runId = (notification.context as NotifyAutomationContext).runId;
          const run = selectActiveRunById(store.getState(), currentTeamId, runId);
          isActive = Boolean(run && run.automation_status === 'paused' && run.state === RUN_STATE.RUNNING);
          break;
        }
        default:
          break;
      }

      if (isActive) {
        notification['active'] = true;
      }
    }
    return notifications;
  }, [notifications, proceduresMetadata, userInfo, users, currentTeamId, store]);

  const uniqNotificationsSorted = useMemo(() => {
    return {
      uniqueLatestSorted: notificationsLib.getUniqLatestSortedNotifications(notificationsWithActiveProperty),
      map: notificationsLib.getNotificationIdMap(notificationsWithActiveProperty),
    };
  }, [notificationsWithActiveProperty]);

  useEffect(() => {
    let resizeTimeoutId: ReturnType<typeof setTimeout>;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        setMainHeight(window.innerHeight - MAIN_VERTICAL_PADDING);
      }, 100);
    };

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: 'Notification',
        name: 'Notification',
        sortable: false,
        width: '38%',
        renderCell({ row }: { row: NotificationRow }) {
          const NotificationLink = NOTIFICATION_LINK_MAP[row.type];
          return (
            NotificationLink && (
              <NotificationLink
                key={row.id}
                notification={row}
                handleOnClick={handleOnClick}
                duplicateIds={uniqNotificationsSorted.map[notificationsLib.getUniqueIdentifier(row)]}
                truncateCode={false}
              />
            )
          );
        },
      },
      {
        key: 'type',
        name: 'Type',
        sortable: false,
        renderCell({ row }: { row: NotificationRow }) {
          return <div className="flex flex-col">{MESSAGE_TYPE_MAP[row.type]}</div>;
        },
      },
      {
        key: 'createdAt',
        name: 'Time',
        sortable: true,
        renderCell({ row }: { row: NotificationRow }) {
          return renderDateTime(row.createdAt);
        },
      },
      {
        key: 'actorId',
        name: 'Sender',
        sortable: true,
        renderCell({ row }: { row: NotificationRow }) {
          return <div className="flex">{row.actorId}</div>;
        },
      },
      {
        key: 'active',
        name: 'Status',
        sortable: true,
        renderCell({ row }: { row: NotificationRow }) {
          return <> {row.active && <Label text="active" size="xs" />}</>;
        },
      },
    ];
  }, [MESSAGE_TYPE_MAP, NOTIFICATION_LINK_MAP, handleOnClick, uniqNotificationsSorted.map]);

  const rows = useMemo(() => {
    const notificationList: Array<NotificationRow> =
      uniqNotificationsSorted.uniqueLatestSorted?.map((notification) => {
        return {
          id: notification.id,
          status: notification.status,
          actorId: notification.actor_id,
          createdAt: notification.created_at,
          userId: notification.user_id,
          type: notification.type,
          context: notification.context,
          active: notification.active,
        };
      }) ?? [];

    if (!searchTerm) {
      return notificationList;
    }
    const keywords = searchTerm.toLowerCase().split(/[\s]+/);
    return notificationList.filter((notification) => {
      const combined = [notification.type, notification.userId, notification.actorId].join(' ').toLowerCase();
      for (const keyword of keywords) {
        if (!combined.includes(keyword)) {
          return false;
        }
      }
      return true;
    });
  }, [searchTerm, uniqNotificationsSorted.uniqueLatestSorted]);

  const getComparator = useCallback((sortColumn) => {
    return (a: NotificationRow, b: NotificationRow) => {
      if (!a[sortColumn.columnKey]) {
        return -1;
      }
      if (!b[sortColumn.columnKey]) {
        return 1;
      }

      if (sortColumn.columnKey === 'active') {
        return a[sortColumn.columnKey] - b[sortColumn.columnKey];
      }

      return a[sortColumn.columnKey].localeCompare(b[sortColumn.columnKey]);
    };
  }, []);

  const sortedRows = useMemo((): Array<NotificationRow> => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns, getComparator]);

  const renderSortStatus = ({ sortDirection }: RenderSortStatusProps) => {
    return (
      <span className="float-left pl-2 pt-1">
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <FontAwesomeIcon className="text-xxs absolute pt-2" icon="chevron-up"></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon className="text-xxs" icon="chevron-down"></FontAwesomeIcon>
          )
        ) : (
          <span>
            <FontAwesomeIcon className="text-xxs absolute pt-2" icon="chevron-up"></FontAwesomeIcon>
            <FontAwesomeIcon className="text-xxs" icon="chevron-down"></FontAwesomeIcon>
          </span>
        )}
      </span>
    );
  };

  return (
    <div className="w-full">
      <Helmet>
        <title>Notifications - List</title>
      </Helmet>
      <div className="flex flex-col flex-grow px-5">
        <div
          className={`fixed pt-4 bg-gray-50 dark:bg-gray-700 transition-all top-0 z-50 ${
            navState.collapsed ? 'left-16' : 'left-64'
          } right-0 ml-4 pl-1 mr-6 pr-1`}
        >
          <div className="flex flex-row">
            <h1 className="pt-2 pl-2">Notifications</h1>
          </div>
          <div className="flex flex-row pb-0 pt-2 bg-gray-50">
            <div className="flex w-full">
              <div className="flex flex-row pb-2">
                <SearchInput placeholder="Search Notifications" onChange={(term) => setSearchTerm(term)} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[134px] lg:mt-[138px] bg-gray-50 dark:bg-gray-700">
          <div className="mt-2 mb-2 flex w-full">
            <div className="divide-x">
              <span className="text-sm text-gray-400 w-42 mr-2">
                {sortedRows.length} {Pluralize('notification', sortedRows.length)}
              </span>
            </div>
          </div>
          <div>
            <DataGrid
              className="fill-grid rdg-light w-full"
              style={{ height: sortedRows.length > 0 ? mainHeight : 40 + NO_SCROLL_PAD }}
              columns={columns}
              rows={sortedRows}
              rowClass={(row) => (row.status === 'unresolved' ? 'font-semibold' : 'bg-gray-100')}
              rowHeight={45}
              headerRowHeight={40}
              renderers={{ renderSortStatus }}
              sortColumns={sortColumns}
              onSortColumnsChange={setSortColumns}
            />
            {(!sortedRows || sortedRows.length === 0) && (
              <div className="flex w-full h-10 bg-white border-b items-center justify-center text-sm font-medium text-center text-gray-400">
                No Notifications Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsList;
